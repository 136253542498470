import React from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

interface BigCallToActionProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Components
}

const Content = styled(ParseContent)`
  & h2 {
    font-size: 28px;
    line-height: 33px;
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    padding-bottom: 15px;
  }

  & p {
    color: ${({ theme }) => theme.color.grey};
    line-height: 26px;
    padding-bottom: 15px;
  }

  @media screen and (min-width: 992px) {
    padding-left: 30px;
  }

  @media screen and (max-width: 991px) {
    & h2 {
      font-size: 26px;
      line-height: 30px;
      padding-bottom: 5px;
    }

    & p {
      padding-bottom: 0;
    }
  }
`

const ImageWrapper = styled.div`
  position: relative;
  border-radius: 25px;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));

  & img {
    border-radius: 25px;
  }

  @media screen and (max-width: 991px) {
    width: 100%;
    margin-left: auto;
  }
`

const BigCallToAction: React.FC<BigCallToActionProps> = ({ fields }) => (
  <section>
    <div className="container py-lg-5">
      <div className="row py-5 justify-content-center">
        <div className="col-4 col-lg-2">
          <ImageWrapper>
            <Plaatjie image={fields.image} alt="cta_image" />
          </ImageWrapper>
        </div>
        <div className="col-8 col-lg-6">
          <Content content={fields.description} />
        </div>
      </div>
    </div>
  </section>
)

export default BigCallToAction
