import React from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

interface CallToActionProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Components
}

const StyledPlaatjie = styled(Plaatjie)`
  border-radius: 20px;
  width: 95px;
  height: 95px;
  margin-right: 20px;

  & img {
    border-radius: 20px;
  }
`

const Block = styled.div`
  background-color: ${({ theme }) => theme.color.primaryLight};
  border-radius: 20px;
  padding: 30px;

  & h2 {
    font-size: 20px;
    line-height: 140%;
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    color: ${({ theme }) => theme.color.darkish};
  }

  & p {
    font-size: 18px;
    line-height: 160%;
  }

  & a {
    font-size: 18px;
    line-height: 160%;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    color: ${({ theme }) => theme.color.primary};

    &:hover {
      text-decoration: underline;
    }
  }
`

const CallToAction: React.FC<CallToActionProps> = ({ fields }) => (
  <section>
    <Block className="d-flex justify-content-center">
      <StyledPlaatjie image={fields.image} alt="" />
      <div>
        <ParseContent content={fields.description} />
        <div className="pt-3">
          <a href={fields.email?.url}>{fields.email?.title}</a>
        </div>
        <div>
          <a href={fields.phone?.url}>{fields.phone?.title}</a>
        </div>
      </div>
    </Block>
  </section>
)

export default CallToAction
