import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import FlexContentHandler from 'components/shared/FlexContentHandler'

interface PageTemplateProps {
  data: {
    page: GatsbyTypes.WpPost
  }
  location?: any
  prefix?: string
  pageContext?: any
}

export const PostContext = React.createContext({})

const PageTemplate: React.FC<PageTemplateProps> = ({
  data: {
    page: { databaseId, title, flexContent, seo },
  },
  location = {},
  prefix = 'Post_Flexcontent',
  pageContext,
}) => (
  <Layout type="post" title={title}>
    <PostContext.Provider value={{ databaseId, title }}>
      <SEO seo={seo} />
      <FlexContentHandler
        prefix={prefix}
        fields={flexContent}
        location={location}
        pageContext={pageContext}
      />
    </PostContext.Provider>
  </Layout>
)

export const pageQuery = graphql`
  query postById($pageId: String!) {
    page: wpPost(id: { eq: $pageId }) {
      ...generalPostFragment
    }
  }
`

export default PageTemplate
