import React from 'react'
import { navigate } from 'gatsby'

// Images
import Chevron from 'img/green_chevron.inline.svg'

// Components
import ParseContent from 'components/shared/ParseContent'
import ButtonOutline from 'components/elements/Buttons/ButtonOutline'

// Third Party
import { Swiper, SwiperSlide } from 'swiper/react'
import styled from 'styled-components'
import 'swiper/css'
import Plaatjie from '@ubo/plaatjie'
import { delay } from 'lodash'

interface ExpertisesProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Components
}

const Wrapper = styled.section`
  /* @media (max-width: 991px) {
    margin-top: 6rem;
  }
  @media (max-width: 449px) {
    margin-top: 12rem;
  } */
`

const Content = styled.div`
  margin-top: -20px;

  & h2 {
    font-size: 100px;
    line-height: 118px;
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    color: ${({ theme }) => theme.color.dark};
    opacity: 0.15;
  }

  @media screen and (max-width: 991px) {
    margin-top: 0;
    margin-bottom: -20px;

    & h2 {
      font-size: 45px;
      line-height: 45px;
    }
  }
`

const Block = styled.div`
  background-color: ${({ theme }) => theme.color.light};
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  padding: 45px 15px 15px 25px;
  border-radius: 25px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  cursor: pointer;

  & h2 {
    font-size: 21px;
    line-height: 25px;
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    padding-bottom: 20px;
  }

  & p {
    color: ${({ theme }) => theme.color.grey};
  }

  &:hover {
    & a,
    button {
      background-color: ${({ theme }) => theme.color.primary};
      color: ${({ theme }) => theme.color.light};
      border: 2px solid ${({ theme }) => theme.color.primary};

      & svg {
        path {
          fill: ${({ theme }) => theme.color.light};
        }
      }
    }
  }

  & > img {
    height: 37px;
    width: 37px;
  }

  @media screen and (max-width: 991px) {
    padding: 25px 10px 10px 15px;
  }
`

const SwiperWrapper = styled.div`
  height: 100%;
  width: 150%;
  position: relative;

  & .swiper-slide-hidden {
    visibility: hidden;
  }

  & .swiper-slide {
    height: auto;
  }
`

const StyledButtonOutline = styled(ButtonOutline)`
  & a,
  button {
    min-width: unset;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & svg {
    width: 12px;
    height: 20px;
  }
`

const Icon = styled(Plaatjie)`
  height: 37px;
  width: 37px;
  margin-bottom: 15px;
`

const SwiperShadow = styled.div`
  position: absolute;
  left: 150px;
  bottom: 50px;
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.color.primary};
  border-radius: 25px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);

  @media screen and (max-width: 991px) {
    left: 50px;
    bottom: -20px;
    height: 90%;
  }
`

const Expertises: React.FC<ExpertisesProps> = ({ fields }) => (
  <Wrapper className="pt-lg-5">
    <div className="container py-5">
      <div className="row pt-lg-5">
        <div className="col-xl-6 mb-5 mb-xl-0">
          <Content>
            <ParseContent content={fields.description} />
          </Content>
        </div>
        <div className="col-xl-6">
          <SwiperWrapper>
            <SwiperShadow />
            <Swiper
              className="p-3"
              spaceBetween={20}
              slidesPerView={2}
              breakpoints={{
                1199: {
                  slidesPerView: 3,
                },
              }}
              autoplay={true}
            >
              {fields.expertises?.map((expertise) => (
                <SwiperSlide>
                  <Block onClick={() => navigate(expertise?.link?.url || '/')}>
                    <Icon image={expertise?.icon} alt="expertise-icon" />
                    <ParseContent content={expertise?.description} />
                    <div className="pt-4 d-flex justify-content-end mt-auto">
                      <StyledButtonOutline to={expertise?.link?.url || '/'}>
                        <Chevron />
                      </StyledButtonOutline>
                    </div>
                  </Block>
                </SwiperSlide>
              ))}
              <SwiperSlide className="d-block swiper-slide-hidden" />
            </Swiper>
          </SwiperWrapper>
        </div>
      </div>
    </div>
  </Wrapper>
)

export default Expertises
