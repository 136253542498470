import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import FlexContentHandler from 'components/shared/FlexContentHandler'

interface PageTemplateProps {
  data: {
    page: GatsbyTypes.WpVacancy
  }
  location?: any
  prefix?: string
  pageContext?: any
}

export const VacancyContext = React.createContext({})

const PageTemplate: React.FC<PageTemplateProps> = ({
  data: {
    page: { vacancyrecap, title, flexContent, seo },
  },
  location = {},
  prefix = 'Vacancy_Flexcontent',
  pageContext,
}) => (
  <Layout title={title}>
    <VacancyContext.Provider value={{ vacancyrecap, title }}>
      <SEO seo={seo} />
      <FlexContentHandler
        prefix={prefix}
        fields={flexContent}
        location={location}
        pageContext={pageContext}
      />
    </VacancyContext.Provider>
  </Layout>
)

export const pageQuery = graphql`
  query vacancyById($pageId: String!) {
    page: wpVacancy(id: { eq: $pageId }) {
      ...generalVacancyFragment
    }
  }
`

export default PageTemplate
