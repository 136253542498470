import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

// Third Party
import BlogBink, { BlogBinkPosts } from '@ubo/blog-bink'

// Components
import PostGrid from 'components/elements/Posts/PostGrid'
import Title from 'components/elements/Title'

// Utils
import { shuffle } from 'src/utils/index'

// Hooks
import usePost from 'hooks/usePost'

interface OtherKnowledgeProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
  location: any
}

const OtherKnowledge: React.FC<OtherKnowledgeProps> = ({ fields }) => {
  const {
    allWpPost,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.OtherKnowledgeQueryQuery>(graphql`
    query OtherKnowledgeQuery {
      allWpPost(
        filter: {
          categories: { nodes: { elemMatch: { slug: { eq: "blog" } } } }
        }
        sort: { order: DESC, fields: date }
      ) {
        edges {
          node {
            ...generalPostFragment
          }
        }
      }
    }
  `)

  const posts: unknown = allWpPost.edges

  shuffle(posts)

  const post: any = usePost()

  return (
    <section>
      <div className="pt-5 container">
        <Title>ook interessant</Title>
      </div>
      <BlogBink
        posts={posts as BlogBinkPosts}
        hideIds={post ? [post.databaseId] : []}
        id="other_stories"
        limit={3}
      >
        <div className="pb-5">
          <PostGrid fields={fields} />
        </div>
      </BlogBink>
    </section>
  )
}

export default OtherKnowledge
