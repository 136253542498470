import React from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'
import Title from 'components/elements/Title'

// Third Party
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

const Block = styled.div`
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  background-color: ${({ theme }) => theme.color.light};
  @media (min-width: 576px) {
    margin-left: 6.5rem;
  }
  @media (max-width: 575px) {
    margin-left: 3rem;
  }
`

const Image = styled(Plaatjie)`
  @media (max-width: 575px) {
    width: 70px;
    height: 70px;
  }
`

const IconWrapper = styled.div`
  left: -100px;
  top: 20px;
  @media (max-width: 575px) {
    left: -40px;
  }
`

const StyledTitle = styled(Title)`
  @media (min-width: 576px) {
    margin-left: 6.5rem;
  }
  @media (max-width: 575px) {
    & h2 {
      font-size: ${({ theme }) => theme.font.size.mega};
    }
  }
`

interface VisionProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Components
}

const Vision: React.FC<VisionProps> = ({ fields }) => (
  <section>
    <div className="container py-lg-5 py-3">
      <div className="row pb-4">
        {fields.visionitems?.map((visionitem, index: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className="col-lg-12" key={index}>
            <div
              className={`row pb-5 ${
                index % 2 === 0
                  ? 'justify-content-start'
                  : 'justify-content-end'
              }`}
            >
              <div className="col-lg-8">
                <StyledTitle>
                  <ParseContent content={visionitem?.title} />
                </StyledTitle>
                <Block className="p-5 position-relative">
                  <IconWrapper className="position-absolute">
                    <Image
                      image={visionitem?.icon}
                      alt={visionitem?.title || 'Icoon'}
                    />
                  </IconWrapper>
                  <ParseContent content={visionitem?.description} />
                </Block>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </section>
)

export default Vision
