import React from 'react'

// Components
import FormContact from 'components/flex/Form/FormContact'
import FormApply from 'components/flex/Form/FormApply'

interface FormShellProps {
  fields: any
  location?: any
  pageContext?: any
}

interface PostProps {
  [key: string]: any
}

const FormShell: React.FC<FormShellProps> = ({
  fields,
  location = {},
  pageContext,
}) => {
  const types: PostProps = {
    contact: FormContact,
    apply: FormApply,
  }

  if (!fields.styletype || !types[fields.styletype]) {
    return null
  }

  const Component = types[fields.styletype]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
      pageContext={pageContext}
    />
  )
}

export default FormShell
