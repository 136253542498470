import React from 'react'
import { navigate } from 'gatsby'

// Images
import Chevron from 'img/white_chevron.inline.svg'

// Components
import ParseContent from 'components/shared/ParseContent'
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'

// Third Party
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

interface VacancyGridPostProps {
  node: GatsbyTypes.WpVacancy
}

const VacancyGridPostExcerpt = styled(ParseContent)`
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  min-height: 75px;
  max-width: 100%;
`

const Content = styled.div`
  background-color: ${({ theme }) => theme.color.light};
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  border-radius: 25px;
  padding: 30px 15px 15px 30px;

  & p {
    color: ${({ theme }) => theme.color.grey};
  }

  & h2 {
    font-size: 22px;
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    padding-bottom: 0px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`

const ImageWrapper = styled.div`
  height: 285px;
  width: 100%;
  position: relative;
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
  margin-bottom: -40px;
  z-index: -1;
  cursor: pointer;

  & img {
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
  }
`

const StyledButtonPrimary = styled(ButtonPrimary)`
  & a {
    min-width: unset;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & svg {
    width: 12px;
    height: 20px;
  }
`

const MetaPoint = styled.div`
  padding-left: 15px;
  position: relative;
  font-weight: ${({ theme }) => theme.font.weight.light};
  font-size: 14px;
  color: ${({ theme }) => theme.color.grey};

  &::before {
    content: '';
    position: absolute;
    height: 10px;
    width: 10px;
    background-color: ${({ theme }) => theme.color.primary};
    border-radius: 5px;
    left: 0;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
  }
`

const VacancyGridPost: React.FC<VacancyGridPostProps> = ({ node }) => (
  <div className="h-100 position-relative">
    <ImageWrapper onClick={() => navigate(node.uri || '/')}>
      <Plaatjie
        image={node.vacancyrecap?.image}
        alt="preview-post"
        className="h-100"
      />
    </ImageWrapper>
    <Content>
      <h2>{node.title}</h2>
      <div className="d-flex pb-3">
        <MetaPoint className="me-4">
          {node.vacancyrecap?.vacancymeta?.level}
        </MetaPoint>
        <MetaPoint className="me-4">
          {node.vacancyrecap?.vacancymeta?.timechoice}
        </MetaPoint>
        <MetaPoint>{node.vacancyrecap?.vacancymeta?.location}</MetaPoint>
      </div>
      <VacancyGridPostExcerpt content={node.vacancyrecap?.excerpt} />
      <div className="d-flex align-items-end justify-content-end mt-4">
        <StyledButtonPrimary to={node.uri || '/'}>
          <Chevron />
        </StyledButtonPrimary>
      </div>
    </Content>
  </div>
)

export default VacancyGridPost
