import React from 'react'

// Components
import PostGridPost from 'components/elements/Posts/PostGridPost'
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'

// Third Party
import { useBlogBink, BlogBinkButton } from '@ubo/blog-bink'
import styled from 'styled-components'

const NoResult = styled.div`
  color: ${({ theme }) => theme.color.primary};
  font-family: ${({ theme }) => theme.font.family.secondary};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  text-align: center;
  font-size: 30px;
  padding: 70px 0;
`

interface PostGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const PostGrid: React.FC<PostGridProps> = ({ fields }) => {
  const blogBink = useBlogBink()

  return (
    <div className="container">
      <div className="row">
        {blogBink.posts.map((post) => {
          const { node }: any = post

          return (
            <div key={post.node.id} className="col-lg-4 mb-lg-5 mb-3">
              <PostGridPost node={node} />
            </div>
          )
        })}
        {blogBink.posts.length === 0 && (
          <NoResult className="d-flex justify-content-center">
            Oeps, het lijkt erop dat wij nog geen artikelen hiervan hebben!
          </NoResult>
        )}
      </div>

      {blogBink.showMoreButton && (
        <div className="my-5 text-center">
          <ButtonPrimary to="/" isCustom>
            <BlogBinkButton>{fields.loadmoretext}</BlogBinkButton>
          </ButtonPrimary>
        </div>
      )}
    </div>
  )
}

export default PostGrid
