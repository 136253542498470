import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import { ButtonPrimaryCSS } from 'components/elements/Buttons/ButtonPrimary'
import { ButtonSecondaryCSS } from 'components/elements/Buttons/ButtonSecondary'

// Components
import FormDuuf from 'components/related/Form'

const StyledFormDuuf = styled(FormDuuf)`
  position: relative;

  & .form-duuf-group {
    margin-bottom: 1.5rem;
    max-width: 400px;
    position: relative;
  }

  & .form-duuf-textarea,
  & .form-duuf-input {
    width: 100%;
    border-radius: 10px;
    border: none;
    min-height: 50px;
    padding: 0 15px;
    font-size: 16px;

    &::placeholder {
      color: ${({ theme }) => theme.color.grey};
    }
  }

  & .form-duuf-textarea {
    padding: 15px;
    height: 160px;
  }

  & .form-duuf-file-button {
    ${ButtonSecondaryCSS};
  }

  & .form-duuf-file-name {
    margin-left: 0.5rem;
  }

  & .form-duuf-checkbox-label {
    margin-left: 0.5rem;
    margin-bottom: 0;
  }

  & .form-duuf-label-mandatory {
    color: ${({ theme }) => theme.color.secondary};
  }

  & .form-duuf-error {
    color: tomato;
    position: absolute;
    right: 15px;
    top: 13px;
    font-size: 14px;

    & + input,
    + textarea {
      border: 1px solid tomato;
    }
  }

  & .form-duuf-submit {
    ${ButtonPrimaryCSS};

    &-disabled {
      opacity: 0.5;
    }
  }

  /* & .form-duuf-confirm {
    color: ${({ theme }) => theme.color.primary};
    font-size: 20px;
    font-weight: ${({ theme }) => theme.font.weight.bold};
  } */

  & .form-duuf-something-wrong {
    color: tomato;
  }

  & .form-duuf-loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.5);
  }
`

interface FormContactProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Form
  location?: any
}

const StyledFormContact = styled.div`
  & p {
    font-size: 20px;
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    color: ${({ theme }) => theme.color.darkish};
  }
`

const FormContact: React.FC<FormContactProps> = ({ fields }) => (
  <StyledFormContact>
    <ParseContent content={fields.description} />
    {/* @ts-ignore */}
    <StyledFormDuuf id={Number(fields.formid) || 1} className="mt-4 pt-2" />
  </StyledFormContact>
)

export default FormContact
