import React from 'react'
import { graphql, useStaticQuery, navigate } from 'gatsby'

// Components
import Chevron from 'img/green_chevron.inline.svg'

// Elements
import HighlightedVacancyGrid from 'components/elements/Vacancies/HighlightedVacancyGrid'
import Title from 'components/elements/Title'

// Third Party
import styled from 'styled-components'
import BlogBink, { BlogBinkPosts } from '@ubo/blog-bink'
import Plaatjie from '@ubo/plaatjie'

interface HighlightedVacancyProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
  location: any
}

const ImageWrapper = styled.div`
  position: relative;
  height: auto;
  width: 100%;
  border-radius: 25px;

  & img {
    border-radius: 25px;
    z-index: -1;
  }
`

const MoreLink = styled.div`
  cursor: pointer;
  position: absolute;
  bottom: -30px;
  right: 25px;
  font-family: ${({ theme }) => theme.font.family.secondary};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  transition: all 0.3s ease;

  &:hover {
    padding-right: 10px;
  }

  & svg {
    width: 6px;
    height: 11px;
    margin-left: 1rem;
  }

  @media screen and (max-width: 991px) {
    bottom: -30px;
  }
`

const HighlightedVacancy: React.FC<HighlightedVacancyProps> = ({ fields }) => {
  const {
    allWpVacancy,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.HighlightedVacancyQueryQuery>(graphql`
    query HighlightedVacancyQuery {
      allWpVacancy(sort: { order: DESC, fields: date }) {
        edges {
          node {
            ...generalVacancyFragment
          }
        }
      }
    }
  `)

  const posts: unknown = allWpVacancy.edges

  //const givenIds: any = fields.vacancyHighlight?.map((h) => h?.databaseId)

  return (
    <section className="pb-5">
      <BlogBink
        posts={posts as BlogBinkPosts}
        id="highlighted_vacancy"
        showIds={[fields?.vacancyHighlight?.[0]?.databaseId]}
      >
        <div className="py-5">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-5 d-flex align-items-center">
                <Title className="pb-lg-5">word een veerman</Title>
              </div>
              <div className="col-xl-6">
                <ImageWrapper>
                  <Plaatjie image={fields.image} alt="word-een-veerman" />
                  <MoreLink onClick={() => navigate('/carriere/vacatures')}>
                    {fields.loadmoretext}
                    <Chevron />
                  </MoreLink>
                </ImageWrapper>
              </div>
            </div>
          </div>
          <HighlightedVacancyGrid fields={fields} />
        </div>
      </BlogBink>
    </section>
  )
}

export default HighlightedVacancy
