import React from 'react'
import ParseContent from 'components/shared/ParseContent'

// Components
import Plaatjie from '@ubo/plaatjie'
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'
import ButtonLight from 'components/elements/Buttons/ButtonLight'

// Third Party
import styled from 'styled-components'

interface BannerSupportProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
  pageContext?: any
}

const StyledBannerSupport = styled.section``

const BannerWrapper = styled.div`
  position: relative;
  height: fit-content;
`

const ImageWrapper = styled.div`
  position: relative;
  height: 600px;
  z-index: -1;
  margin-bottom: 150px;

  @media (max-width: 991px) {
    height: 400px;
  }
`

const StyledImage = styled(Plaatjie)`
  height: 100%;
  border-bottom-left-radius: 180px;

  @media screen and (max-width: 991px) {
    border-bottom-left-radius: 90px;
  }
`

const ContentWrapper = styled.div`
  position: absolute;
  bottom: 80px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  padding-top: 350px;

  color: ${(props) => props.theme.color.grey};

  @media screen and (max-width: 991px) {
    position: relative;
    padding-top: 0;
    bottom: 0;
    margin-top: -400px;
  }
`

const Block = styled.div`
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  background-color: ${({ theme }) => theme.color.light};
  min-height: 322px;

  & h1,
  h2,
  h3 {
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-weight: ${({ theme }) => theme.font.weight.extraBold};
    color: ${({ theme }) => theme.color.darkish};
    font-size: 20px;
    line-height: 24px;
    padding-bottom: 20px;
  }

  & p {
    font-size: 16px;
    line-height: 22px;
    font-weight: ${({ theme }) => theme.font.weight.light};
    text-align: justify;
    margin-bottom: 20px;
  }

  @media (max-width: 991px) {
    min-height: auto !important;
  }

  @media (max-width: 1199px) {
    min-height: 350px;
  }

  @media screen and (min-width: 992px) {
    margin-top: 60px;
    padding: 40px;
  }

  @media screen and (max-width: 991px) {
    padding: 20px;
    margin-bottom: 20px;
  }
`

const AltBlock = styled.div`
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  background-color: ${({ theme }) => theme.color.primary};
  color: ${({ theme }) => theme.color.light};
  min-height: 322px;

  & h1,
  h2,
  h3 {
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-weight: ${({ theme }) => theme.font.weight.extraBold};
    color: ${({ theme }) => theme.color.darkish};
    font-size: 20px;
    line-height: 24px;
    padding-bottom: 20px;
  }

  & p {
    font-size: 16px;
    line-height: 22px;
    font-weight: ${({ theme }) => theme.font.weight.light};
    text-align: justify;
    margin-bottom: 20px;
  }

  @media (max-width: 991px) {
    min-height: auto !important;
  }

  @media (max-width: 1199px) {
    min-height: 350px;
  }

  @media screen and (min-width: 992px) {
    margin-top: 60px;
    padding: 40px;
  }

  @media screen and (max-width: 991px) {
    padding: 20px;
    margin-bottom: 20px;
  }
`

const ThirdBlock = styled.div`
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  background-color: ${({ theme }) => theme.color.secondary};
  color: ${({ theme }) => theme.color.dark};
  min-height: 322px;

  & h1,
  h2,
  h3 {
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-weight: ${({ theme }) => theme.font.weight.extraBold};
    color: ${({ theme }) => theme.color.darkish};
    font-size: 20px;
    line-height: 24px;
    padding-bottom: 20px;
  }

  & p {
    font-size: 16px;
    line-height: 22px;
    font-weight: ${({ theme }) => theme.font.weight.light};
    text-align: justify;
    margin-bottom: 20px;
  }

  @media (max-width: 991px) {
    min-height: auto !important;
  }

  @media (max-width: 1199px) {
    min-height: 350px;
  }

  @media screen and (min-width: 992px) {
    margin-top: 60px;
    padding: 40px;
  }

  @media screen and (max-width: 991px) {
    padding: 20px;
    margin-bottom: 20px;
  }
`

const BannerSupport: React.FC<BannerSupportProps> = ({ fields }) => (
  <StyledBannerSupport>
    <BannerWrapper>
      <ImageWrapper className="d-flex justify-content-center">
        <StyledImage loading="eager" alt="banner" image={fields?.image} />
      </ImageWrapper>
      <ContentWrapper>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 h-100">
              <Block className="d-flex flex-column justify-content-between h-100">
                <ParseContent content={fields.description} />
                <div className="pt-5">
                  <ButtonPrimary isAnchor to={fields.link?.url || '/'}>
                    {fields.link?.title}
                  </ButtonPrimary>
                </div>
              </Block>
            </div>
            <div className="col-lg-4 h-100">
              <AltBlock className="d-flex flex-column justify-content-between h-100">
                <ParseContent content={fields.seconddescription} />
                <div className="pt-5">
                  <ButtonLight isCustom to="">
                    <a
                      href="http://portal.veermanict.nl/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Open portal
                    </a>
                  </ButtonLight>
                </div>
              </AltBlock>
            </div>
            <div className="col-lg-4 h-100">
              <ThirdBlock className="d-flex flex-column justify-content-between h-100">
                <ParseContent content={fields.thirddescription} />
                <div className="pt-5">
                  <ButtonPrimary isCustom to="">
                    <a
                      href={fields.thirdlink?.url || '/'}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {fields.thirdlink?.title}
                    </a>
                  </ButtonPrimary>
                </div>
              </ThirdBlock>
            </div>
          </div>
        </div>
      </ContentWrapper>
    </BannerWrapper>
  </StyledBannerSupport>
)

export default BannerSupport
