import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

// Elements
import BlogBink, { BlogBinkPosts } from '@ubo/blog-bink'
import PostGrid from 'components/elements/Posts/PostGrid'
import PostFilters from 'components/elements/Posts/PostFilters'

interface KnowledgeBaseProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
  location: any
}

const KnowledgeBase: React.FC<KnowledgeBaseProps> = ({ fields }) => {
  const {
    allWpPost,
    allWpCategory,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.knowledgeBaseQueryQuery>(graphql`
    query knowledgeBaseQuery {
      allWpPost(
        sort: { order: DESC, fields: date }
        filter: {
          categories: {
            nodes: { elemMatch: { name: { ne: "Klantverhalen" } } }
          }
        }
      ) {
        edges {
          node {
            ...generalPostFragment
          }
        }
      }
      allWpCategory(
        filter: {
          name: {
            nin: ["Uncategorized", "Klantverhalen", "Medewerkersverhalen"]
          }
        }
      ) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `)

  const posts: unknown = allWpPost.edges

  return (
    <section>
      <BlogBink
        posts={posts as BlogBinkPosts}
        categories={allWpCategory.edges}
        id="knowledge_base_normal"
        limit={Number(fields.limit)}
      >
        <div className="py-5">
          <PostFilters />
          <PostGrid fields={fields} />
        </div>
      </BlogBink>
    </section>
  )
}

export default KnowledgeBase
