import React from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'
import ButtonLight from 'components/elements/Buttons/ButtonLight'

// Third Party
import styled from 'styled-components'

interface HighlightedVacancyGridPostProps {
  // eslint-disable-next-line
  blogFields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
  node: GatsbyTypes.WpVacancy
}

const HighlightedVacancyGridPostExcerpt = styled(ParseContent)`
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  min-height: 75px;
  max-width: 100%;

  & p {
    color: ${({ theme }) => theme.color.light};
    font-size: 16px;
    line-height: 21.5px;
  }
`

const Block = styled.div`
  background-color: ${({ theme }) => theme.color.primary};
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  border-radius: 25px;
  padding: 40px;

  & h2 {
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-size: 28px;
    line-height: 33px;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 991px) {
    padding: 20px 15px;
  }
`

const HighlightedVacancyGridPost: React.FC<HighlightedVacancyGridPostProps> = ({
  node,
  blogFields,
}) => (
  <Block>
    <h2>{node.title}</h2>
    <HighlightedVacancyGridPostExcerpt content={node.vacancyrecap?.excerpt} />
    <div className="mt-2">
      <ButtonLight to={node.uri}>{blogFields.readmoretext}</ButtonLight>
    </div>
  </Block>
)

export default HighlightedVacancyGridPost
