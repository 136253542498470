import React from 'react'

// Components
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'
import HighlightedVacancyGridPost from 'components/elements/Vacancies/HighlightedVacancyGridPost'

// Third Party
import { useBlogBink, BlogBinkButton } from '@ubo/blog-bink'
import styled from 'styled-components'

interface HighlightedVacancyGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const GridWrapper = styled.div`
  margin-top: -205px !important;

  @media screen and (max-width: 991px) {
    margin-top: 0 !important;
  }
`

const HighlightedVacancyGrid: React.FC<HighlightedVacancyGridProps> = ({
  fields,
}) => {
  const blogBink = useBlogBink()

  return (
    <div className="container">
      <GridWrapper className="row">
        <div className="col-lg-2" />
        {blogBink.posts.map((post) => {
          const { node }: any = post

          return (
            <div key={post.node.id} className="col-12 col-lg-5 mt-5 pt-lg-5">
              <HighlightedVacancyGridPost node={node} blogFields={fields} />
            </div>
          )
        })}
      </GridWrapper>

      {blogBink.showMoreButton && (
        <div className="mt-5 text-center">
          <ButtonPrimary to="/" isCustom>
            <BlogBinkButton>{fields.loadmoretext}</BlogBinkButton>
          </ButtonPrimary>
        </div>
      )}
    </div>
  )
}

export default HighlightedVacancyGrid
