/* eslint-disable react/no-array-index-key */
import React from 'react'
import { useStaticQuery, graphql, navigate } from 'gatsby'

// Components
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

// Images
import Message from 'img/message.inline.svg'

// Third Party
import styled from 'styled-components'
import { cloneDeep } from 'lodash'

interface TeamProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Components
}

const ContentWrapper = styled.div`
  padding: 10px 20px 30px 20px;

  & p {
    font-size: 18px;
    line-height: 24px;
    /* font-weight: ${({ theme }) => theme.font.weight.extraBold}; */

    & b,
    strong {
      font-size: 20px;
    }
  }

  @media screen and (max-width: 575px) {
    padding: 5px 10px 20px 10px;

    & p {
      font-size: 20px;
      line-height: 20px;
    }
  }

  @media screen and (max-width: 350px) {
    padding: 5px 10px 20px 10px;

    & p {
      font-size: 16px;
      line-height: 16px;
    }
  }
`

const ImgWrapper = styled.div`
  width: 100%;
  padding-top: 100%;
  position: relative;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
  border-radius: 25px;
`

const Bubble = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: ${({ theme }) => theme.color.primary};
  padding: 10px;
  border-radius: 999px;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }
`

const StyledPlaatjie = styled(Plaatjie)`
  position: absolute !important;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  border-radius: 25px;
`

const Team: React.FC<TeamProps> = ({ fields }) => {
  const [team, setTeam] = React.useState<GatsbyTypes.WpTeam[]>([])
  const { allWpTeam } = useStaticQuery<GatsbyTypes.teamQuery>(graphql`
    query team {
      allWpTeam(sort: { fields: menuOrder, order: ASC }) {
        nodes {
          teamrecap {
            excerpt
            story {
              ... on WpPost {
                uri
              }
            }
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100, width: 800, placeholder: NONE)
                }
              }
            }
          }
        }
      }
    }
  `)

  React.useEffect(() => {
    const t = cloneDeep(allWpTeam.nodes) as GatsbyTypes.WpTeam[]
    const firstTeam = t.pop()

    const correctTeam = [firstTeam, ...t] as GatsbyTypes.WpTeam[]

    setTeam(correctTeam)
  }, [])


  return (
    <section>
      <div className="container py-5">
        <div>
          <ParseContent content={fields.description || ''} />
          <div className="row py-4">
            {team.map((t, index) => (
              <div key={index} className="col-6 col-lg-3">
                <div>
                  <ImgWrapper>
                    <StyledPlaatjie image={t.teamrecap?.image} alt="" />
                    {t.teamrecap?.story && (
                      <Bubble
                        onClick={() => navigate(t.teamrecap?.story?.uri || '/')}
                      >
                        <Message />
                      </Bubble>
                    )}
                  </ImgWrapper>
                  <ContentWrapper>
                    <ParseContent content={t.teamrecap?.excerpt || ''} />
                  </ContentWrapper>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Team
