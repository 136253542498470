import React from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled, { css } from 'styled-components'
import Plaatjie from '@ubo/plaatjie'
import { motion, AnimatePresence } from 'framer-motion'

interface MethodWithTextProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Components
}

const Title = styled(ParseContent)`
  font-family: ${({ theme }) => theme.font.family.secondary};
  font-size: 20px;
  line-height: 24px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  transition: all 0.3s ease;
`

const Block = styled(motion.div)<{ active: boolean }>`
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  padding: 40px 30px;
  border-radius: 25px;
  transition: all 0.3s ease;
  position: relative;
  width: 100%;

  & svg path {
    fill: ${({ theme }) => theme.color.primary};
  }

  ${({ active, theme }) =>
    active
      ? css`
          background-color: #e6e5e5;

          ${Title} {
            color: ${theme.color.primary};
          }

          &:before {
            content: '';
            position: absolute;
            border-top: 10px solid #e6e5e5;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid transparent;
            bottom: -20px;
            left: calc(50% - 10px);
          }
        `
      : css`
          background-color: ${theme.color.light};

          ${Title} {
            color: ${theme.color.dark};
          }
        `}

  @media screen and (max-width: 991px) {
    padding: 20px 30px;
    margin-bottom: 10px;
    margin-top: 5px;

    &:before {
      content: none;
    }
  }
`

const Icon = styled(Plaatjie)`
  margin-bottom: 15px;
  width: 35px;
  height: 35px;
  object-position: contain;
`

const MobileContent = styled(motion.div)`
  & p {
    color: ${({ theme }) => theme.color.grey};
  }
`

const Content = styled(ParseContent)`
  & p {
    font-size: 16px;
    line-height: 26px;
    color: ${({ theme }) => theme.color.grey};
  }
`

const BlockWrapper = styled.div`
  /* overflow: hidden; */
`

const MethodWithText: React.FC<MethodWithTextProps> = ({ fields }) => {
  const [current, setCurrent] = React.useState(1)

  return (
    <section>
      <div className="container py-lg-5 py-3">
        <div className="row justify-content-center pb-4">
          {fields.methoditems?.map((slideritem, index: number) => (
            <BlockWrapper className="col-lg d-flex flex-wrap">
              <Block
                onHoverStart={() => setCurrent(index)}
                onTap={() => setCurrent(index)}
                active={current === index}
              >
                <Icon image={slideritem?.icon} alt="icon" />
                <Title content={slideritem?.title} />
              </Block>
              <AnimatePresence>
                {current === index && (
                  <MobileContent
                    className="d-lg-none d-flex"
                    initial={{ opacity: 0, height: '0px' }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: '0px' }}
                  >
                    <div className="p-3 pt-0">
                      <ParseContent
                        content={fields.methoditems![current]?.description}
                      />
                    </div>
                  </MobileContent>
                )}
              </AnimatePresence>
            </BlockWrapper>
          ))}
        </div>
        <div className="d-lg-flex d-none row pt-5 justify-content-center">
          <div className="col-lg-5">
            <Content content={fields.methoditems![current]?.description} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default MethodWithText
