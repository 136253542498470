import React from 'react'

// Third Party
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay } from 'swiper/core'
import Plaatjie from '@ubo/plaatjie'
import 'swiper/css'

SwiperCore.use([Autoplay])

interface SliderProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Components
}

const Block = styled.div`
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  border-radius: 25px;
  padding: 40px;
  background-color: ${({ theme }) => theme.color.light};

  & .swiper-wrapper {
    align-items: center;
  }

  @media screen and (min-width: 992px) {
    width: 1230px;
    margin-left: 160px;
  }

  @media screen and (max-width: 991px) {
    padding: 30px;
  }
`

const BlockShadow = styled.div`
  width: 2000px;
  position: absolute;
  background-color: ${({ theme }) => theme.color.primary};
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  border-radius: 25px;
  height: 100%;
  left: -1700px;
  top: -40px;
  z-index: -1;
`

const Image = styled(Plaatjie)`
  filter: grayscale(100%);
  transition: filter 0.3s ease-in-out;
  &:hover {
    filter: unset;
  }
`

const Slider: React.FC<SliderProps> = ({ fields }) => (
  <section>
    <div className="container py-5">
      <div className="position-relative">
        <BlockShadow />
        <Block>
          <Swiper
            spaceBetween={50}
            slidesPerView={2}
            loop
            breakpoints={{
              575: {
                slidesPerView: 3,
                spaceBetween: 150,
              },
              992: {
                spaceBetween: 100,
                slidesPerView: 5,
              },
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
          >
            {fields.slideritems?.map((item) => (
              <SwiperSlide>
                <a href={item?.link?.url} target="_blank" rel="noreferrer">
                  <Image image={item?.image} alt={item?.link?.title || ''} />
                </a>
              </SwiperSlide>
            ))}
          </Swiper>
        </Block>
      </div>
    </div>
  </section>
)

export default Slider
