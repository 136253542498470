import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { Helmet } from 'react-helmet'

// Icon
import favicon from 'img/favicon.png'

const removeHTML = (content: string) => {
  let newContent = content

  if (typeof document !== 'undefined') {
    const titleHtml = newContent
    const div = document.createElement('div')
    div.innerHTML = titleHtml
    newContent = div.innerText
  }

  return newContent
}

interface SEOProps {
  seo: any
  id: number
}

const SEO: React.FC<SEOProps> = ({ seo, id }) => {
  const {
    site,
    wpComponent,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.socialSharingQueryQuery>(graphql`
    query socialSharingQuery {
      site {
        siteMetadata {
          siteUrl
        }
      }
      wpComponent(databaseId: { eq: 204 }) {
        socialSharing {
          image {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  `)

  const defaultImageUrl = wpComponent?.socialSharing?.image
    ? {
      url: `${site?.siteMetadata?.siteUrl}${wpComponent?.socialSharing?.image?.localFile?.publicURL}`,
    }
    : ''

  const image: any = seo.opengraphImage
    ? {
      url: seo.opengraphImage.sourceUrl,
      width: 800,
      height: 600,
      alt: seo.opengraphTitle,
    }
    : defaultImageUrl

  return (
    <>
      <GatsbySeo
        language="nl"
        title={removeHTML(seo.title) || ''}
        description={removeHTML(seo.metaDesc) || ''}
        canonical={seo.canonical || ''}
        openGraph={{
          type: seo.opengraphTitle || '',
          url: seo.opengraphUrl || '',
          title: removeHTML(seo.title) || '',
          description: removeHTML(seo.metaDesc) || '',
          images: [image],
        }}
      />
      <Helmet>
        <link rel="icon" href={favicon} />
        <meta name="google-site-verification" content="PkqZra4dbm9c6L0L939dHrF2dJVtrMUIPwXbXWKr6Gg" />
        <script
          id="Cookiebot"
          src="https://consent.cookiebot.com/uc.js"
          data-cbid="151eba57-dc5e-4c4f-9bd9-0d182a17d049"
          type="text/javascript"
          async
        />
        <script>
          {`
          (function(l,e,a,d,i,n,f,o){if(!l[i]){l.GlobalLeadinfoNamespace=l.GlobalLeadinfoNamespace||[]; l.GlobalLeadinfoNamespace.push(i);l[i]=function(){(l[i].q=l[i].q||[]).push(arguments)};l[i].t=l[i].t||n; l[i].q=l[i].q||[];o=e.createElement(a);f=e.getElementsByTagName(a)[0];o.async=1;o.src=d;f.parentNode.insertBefore(o,f);} }(window,document,"script","https://cdn.leadinfo.net/ping.js","leadinfo","LI-624ED8964D1AB"));
          `}
        </script>

        {id === 1506 && (
          <script>
            {`
              (function(w, d, s, o, f, js, fjs) {
              w['SendmarcWidget'] = o;
              w[o] = w[o] || function() {
              (w[o].q = w[o].q || []).push(arguments)
              };
              js = d.createElement(s), fjs = d.getElementsByTagName(s)[0];
              js.id = o;
              js.src = f;
              js.async = 1;
              fjs.parentNode.insertBefore(js, fjs);
              }(window, document, 'script', 'smConfig', 'https://madox.sendmarc.com/widget.js'));
              const params = new URLSearchParams(window.location.search);
              // The config below is an example that you can use to get started. Change any of the configuration options below to style the widget according to your site
              config = {
                target: 'sendmarc-widget-form',
                placement: 'modal',
                modelWidth: '450px',
                styles: {
                    success: {
                        primary: '#76B459',
                        secondary: '#527E3E',
                        icon: 'M4.5 12.75l6 6 9-13.5',
                        },
                    warning: {
                        primary: '#ca8a04',
                        secondary: '#fde047',
                        icon: 'M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0M3.124 7.5A8.969 8.969 0 015.292 3m13.416 0a8.969 8.969 0 012.168 4.5',
                        },
                    danger: {
                        primary: '#dc2626',
                        secondary: '#fca5a5',
                        icon: 'M6 18L18 6M6 6l12 12',
                        },
                  },

                call_to_action: {
                    title: 'Domein beveiligen?',
                    href: 'http://sendmarc.com',
                    threshold: 4,
                    classes: '_sm-call-to-action-button',
                },
              }
              smConfig('5b92f9b0-0e5d-40c1-b993-7000340bd8c8', config);
          `}
          </script>

        )}

      </Helmet>
    </>
  )
}

export default SEO
