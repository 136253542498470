/* eslint-disable react/no-array-index-key */
import React from 'react'
import ParseContent from 'components/shared/ParseContent'
import { useStaticQuery, graphql } from 'gatsby'

// Components
import Plaatjie from '@ubo/plaatjie'

// Third Party
import styled, { css } from 'styled-components'
import { useFlexSearch } from 'react-use-flexsearch'
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'

interface BannerSearchProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
}

const StyledBannerSearch = styled.section``

const BannerWrapper = styled.div`
  position: relative;
  height: fit-content;
`

const ImageWrapper = styled.div`
  position: relative;
  height: 500px;
  z-index: -1;
  margin: 0 0 100px 0;

  @media screen and (max-width: 991px) {
    height: 300px;
  }
`

const StyledImage = styled(Plaatjie)`
  height: 100%;
  border-bottom-left-radius: 180px;

  @media screen and (max-width: 991px) {
    border-bottom-left-radius: 90px;
  }
`

const ContentWrapper = styled.div`
  position: absolute;
  bottom: -20px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 10;
  color: ${(props) => props.theme.color.grey};
`

const Block = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  background-color: ${({ theme }) => theme.color.light};

  & h1,
  h2,
  h3 {
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-weight: ${({ theme }) => theme.font.weight.extraBold};
    color: ${({ theme }) => theme.color.darkish};
    font-size: 26px;
    line-height: 31px;
    text-align: center;
  }

  & p {
    font-size: 16px;
    line-height: 22px;
    font-weight: ${({ theme }) => theme.font.weight.light};
    text-align: justify;
    margin-bottom: 20px;
  }

  & a {
    margin-top: 15px;
  }

  @media screen and (min-width: 992px) {
    margin-top: 60px;
    margin-left: 100px;
    padding: 30px;
    max-width: 420px;
  }

  @media screen and (max-width: 991px) {
    padding: 20px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
  }
`

const SearchInput = styled.input`
  width: 100%;
  background-color: ${({ theme }) => theme.color.greyLight};
  border: none;
  outline: none;
  padding: 10px 15px;
  border-radius: 5px;
`

const SearchResult = styled.div<{ last?: boolean }>`
  padding: 12px 0;
  position: relative;

  ${(props) =>
    !props.last &&
    css`
      &::before {
        content: '';
        width: 90%;
        height: 1px;
        background-color: ${({ theme }) => theme.color.grey};
        opacity: 0.3;
        position: absolute;
        bottom: 0;
        left: 5%;
      }
    `}

  @media screen and (max-width: 350px) {
    flex-wrap: wrap;
  }
`

const ResultBlock = styled.div`
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  padding: 40px;
`

const Title = styled.div`
  color: ${({ theme }) => theme.color.primary};
  font-weight: ${({ theme }) => theme.font.weight.bold};
`

const StyledButtonPrimary = styled(ButtonPrimary)`
  @media screen and (max-width: 991px) {
    font-size: 14px;

    & a {
      padding: 10px 15px;
    }
  }
`

const BannerSearch: React.FC<BannerSearchProps> = ({ fields }) => {
  const [input, setInput] = React.useState<string | null>('')

  const { pages } = useStaticQuery<GatsbyTypes.searchQuery>(graphql`
    query search {
      pages: localSearchPages {
        index
        store
      }
    }
  `)

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const params = urlParams.get('query')

    setInput(params)
  }, [])

  const pageResults = useFlexSearch(input, pages?.index, pages?.store, {
    resolution: 1,
  })

  return (
    <StyledBannerSearch>
      <BannerWrapper>
        <ImageWrapper className="d-flex justify-content-center">
          <StyledImage loading="eager" alt="banner" image={fields?.image} />
        </ImageWrapper>
        <ContentWrapper>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <Block>
                  <ParseContent content={fields.description} />
                  <SearchInput
                    className="mt-4"
                    placeholder="Typ hier je zoekwoorden..."
                    onChange={(e) => setInput(e.target.value)}
                  />
                </Block>
              </div>
            </div>
          </div>
        </ContentWrapper>
      </BannerWrapper>
      <div className="container pb-lg-5 pb-3">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            {input !== null && (
              <ResultBlock>
                {pageResults.length > 0 &&
                  pageResults.map((r: any, index: number) => (
                    <SearchResult
                      last={index === pageResults.length - 1}
                      className="row align-items-center"
                      key={index}
                    >
                      <div className="col-lg-8">
                        <Title>{r.title}</Title>
                      </div>
                      <div className="col-lg-4">
                        <div className="d-flex justify-content-sm-end justify-content-center py-sm-0 py-2">
                          <StyledButtonPrimary to={r.uri}>
                            {`Ga naar ${r.type}`}
                          </StyledButtonPrimary>
                        </div>
                      </div>
                    </SearchResult>
                  ))}
                {input !== null && pageResults < 1 && (
                  <span>
                    Helaas, binnen deze zoekterm hebben we geen resultaten
                    gevonden. Probeer een andere zoekopdracht voor betere
                    resultaten.
                  </span>
                )}
              </ResultBlock>
            )}
          </div>
        </div>
      </div>
    </StyledBannerSearch>
  )
}

export default BannerSearch
