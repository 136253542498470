import React from 'react'
import { navigate } from 'gatsby'

// Images
import Chevron from 'img/white_chevron.inline.svg'
import Clock from 'img/clock.inline.svg'

// Components
import ParseContent from 'components/shared/ParseContent'
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'

// Third Party
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

interface PostGridPostProps {
  node: GatsbyTypes.WpPost
}

const PostGridPostExcerpt = styled(ParseContent)`
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  min-height: 75px;
  max-width: 100%;
`

const Content = styled.div`
  background-color: ${({ theme }) => theme.color.light};
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  border-radius: 25px;
  padding: 30px 15px 15px 30px;

  & p {
    color: ${({ theme }) => theme.color.grey};
  }

  & h2 {
    font-size: 22px;
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`

const ImageWrapper = styled.div`
  height: 285px;
  width: 100%;
  position: relative;
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
  margin-bottom: -40px;
  z-index: -1;
  cursor: pointer;

  & > div {
    height: 100%;
  }

  & img {
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
    height: 100%;
  }
`

const StyledButtonPrimary = styled(ButtonPrimary)`
  & a {
    min-width: unset;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & svg {
    width: 12px;
    height: 20px;
  }
`

const ReadTime = styled.div`
  color: ${({ theme }) => theme.color.grey};
  font-size: 14px;

  & svg {
    margin-right: 0.5rem;
  }
`

const PostGridPost: React.FC<PostGridPostProps> = ({ node }) => (
  <>
    <ImageWrapper onClick={() => navigate(node.uri)}>
      <Plaatjie image={node.recap?.image} alt="preview-post" />
    </ImageWrapper>
    <Content>
      <h2>{node.title}</h2>
      <PostGridPostExcerpt content={node.recap?.excerpt} />
      <div className="d-flex align-items-center justify-content-between mt-4">
        <ReadTime>
          <Clock />
          {node.recap?.postmeta?.readtime}
        </ReadTime>
        <StyledButtonPrimary to={node.uri}>
          <Chevron />
        </StyledButtonPrimary>
      </div>
    </Content>
  </>
)

export default PostGridPost
