import React from 'react'

// Components
import Blog from 'components/flex/Posts/Blog'
import KnowledgeBase from 'components/flex/Posts/KnowledgeBase'
import Vacancies from 'components/flex/Posts/Vacancies'
import OtherStories from 'components/flex/Posts/OtherStories'
import Stories from 'components/flex/Posts/Stories'
import HighlightedStories from 'components/flex/Posts/HighlightedStories'
import PreviewKnowledgeBase from 'components/flex/Posts/PreviewKnowledgeBase'
import HighlightedVacancy from 'components/flex/Posts/HighlightedVacancy'
import HighlightedVacancies from 'components/flex/Posts/HighlightedVacancies'
import OtherKnowledge from 'components/flex/Posts/OtherKnowledge'

interface PostsShellProps {
  fields: any
  location?: any
  pageContext?: any
}

interface PostProps {
  [key: string]: any
}

const PostsShell: React.FC<PostsShellProps> = ({
  fields,
  location = {},
  pageContext,
}) => {
  const types: PostProps = {
    blog: Blog,
    knowledge_base: KnowledgeBase,
    vacancy: Vacancies,
    stories: Stories,
    knowledge_base_preview: PreviewKnowledgeBase,
    highlighted_vacancy: HighlightedVacancy,
    highlighted_vacancies: HighlightedVacancies,
    highlighted_stories: HighlightedStories,
    other_stories: OtherStories,
    other_knowledge: OtherKnowledge,
  }

  if (!fields.styletype || !types[fields.styletype]) {
    return null
  }

  const Component = types[fields.styletype]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
      pageContext={pageContext}
    />
  )
}

export default PostsShell
