import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

// Elements
import BlogBink, { BlogBinkPosts } from '@ubo/blog-bink'
import VacancyGrid from 'components/elements/Vacancies/VacancyGrid'

interface VacanciesProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
  location: any
}

const Vacancies: React.FC<VacanciesProps> = ({ fields }) => {
  const {
    allWpVacancy,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.VacanciesQueryQuery>(graphql`
    query VacanciesQuery {
      allWpVacancy(sort: { order: ASC, fields: menuOrder }) {
        edges {
          node {
            ...generalVacancyFragment
          }
        }
      }
    }
  `)

  const posts: unknown = allWpVacancy.edges

  return (
    <section>
      <BlogBink
        posts={posts as BlogBinkPosts}
        id="knowledge_base"
        limit={Number(fields.limit)}
      >
        <div className="py-5">
          <VacancyGrid fields={fields} />
        </div>
      </BlogBink>
    </section>
  )
}

export default Vacancies
