import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

// Components
import HighlightedVacanciesGrid from 'components/elements/Vacancies/HighlightedVacanciesGrid'
import Title from 'components/elements/Title'

// Third Party
import BlogBink, { BlogBinkPosts } from '@ubo/blog-bink'

interface HighlightedVacanciesProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
  location: any
}

const HighlightedVacancies: React.FC<HighlightedVacanciesProps> = ({
  fields,
}) => {
  const {
    allWpVacancy,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.HighlightedMultipleVacanciesQueryQuery>(graphql`
    query HighlightedMultipleVacanciesQuery {
      allWpVacancy(sort: { order: DESC, fields: date }) {
        edges {
          node {
            ...generalVacancyFragment
          }
        }
      }
    }
  `)

  const posts: unknown = allWpVacancy.edges

  const givenIds: any = fields.vacancyHighlight?.map((h) => h?.databaseId)

  return (
    <section className="pb-lg-5">
      <BlogBink
        posts={posts as BlogBinkPosts}
        id="highlighted_vacancies"
        showIds={givenIds}
      >
        <div className="py-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 d-flex align-items-center">
                <Title>vacatures</Title>
              </div>
            </div>
          </div>
          <HighlightedVacanciesGrid fields={fields} />
        </div>
      </BlogBink>
    </section>
  )
}

export default HighlightedVacancies
