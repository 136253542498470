import React from 'react'
import ParseContent from 'components/shared/ParseContent'

// Components
import Plaatjie from '@ubo/plaatjie'

// Third Party
import styled from 'styled-components'
import BreadCrumb from 'components/elements/BreadCrumb'

interface BannerPostProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
  pageContext?: any
}

const StyledBannerPost = styled.section``

const BannerWrapper = styled.div`
  position: relative;
  height: fit-content;
`

const ImageWrapper = styled.div`
  position: relative;
  height: 500px;
  z-index: -1;
  margin-bottom: 100px;
  border-radius: 0px 0px 0px 180px;

  @media screen and (max-width: 991px) {
    height: 300px;
  }
`

const StyledImage = styled(Plaatjie)`
  height: 100%;
  border-bottom-left-radius: 180px;
  z-index: 1;
  position: relative;

  @media screen and (max-width: 991px) {
    border-bottom-left-radius: 90px;
  }
`

const FocusedImage = styled(Plaatjie)`
  /* position: absolute !important; */
  height: 400px;
  width: 600px;
  margin-top: 0px;
  border-radius: 20px;

  & img {
    border-radius: 20px;
    object-position: top;
  }

  @media screen and (max-width: 991px) {
    display: none !important;
  }
`

const ContentWrapper = styled.div`
  position: absolute;
  bottom: 80px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  padding-top: 250px;

  color: ${(props) => props.theme.color.grey};
`

const Block = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  background-color: ${({ theme }) => theme.color.light};

  & h1,
  h2,
  h3 {
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-weight: ${({ theme }) => theme.font.weight.extraBold};
    color: ${({ theme }) => theme.color.darkish};
    font-size: 26px;
    line-height: 31px;
  }

  & p {
    font-size: 16px;
    line-height: 22px;
    font-weight: ${({ theme }) => theme.font.weight.light};
    text-align: justify;
    margin-bottom: 20px;
  }

  @media screen and (min-width: 992px) {
    margin-top: 60px;
    margin-left: 50px;
    padding: 30px;
    max-width: 420px;
  }

  @media screen and (max-width: 991px) {
    padding: 30px;
  }
`

const Gradient = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.color.primary};
  opacity: 0.75;
  z-index: 3;
  border-bottom-left-radius: 180px;

  @media screen and (max-width: 991px) {
    border-bottom-left-radius: 90px;
  }
`

const OtherGradient = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background: #76b459;
  mix-blend-mode: multiply;
  z-index: 2;
  border-bottom-left-radius: 180px;
  top: 0;
  left: 0;

  @media screen and (max-width: 991px) {
    border-bottom-left-radius: 90px;
  }
`

const BannerPost: React.FC<BannerPostProps> = ({ pageContext, fields }) => (
  <StyledBannerPost>
    <BannerWrapper>
      <ImageWrapper>
        <Gradient />
        <OtherGradient />
        <StyledImage loading="eager" alt="banner" image={fields?.image} />
      </ImageWrapper>
      <ContentWrapper>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <Block>
                <BreadCrumb label="" pageContext={pageContext} />
                <ParseContent content={fields.description} />
              </Block>
            </div>
            <div className="col-lg-6 d-flex justify-content-end">
              <FocusedImage
                loading="eager"
                alt="focused-image"
                image={fields?.image}
              />
            </div>
          </div>
        </div>
      </ContentWrapper>
    </BannerWrapper>
  </StyledBannerPost>
)

export default BannerPost
