import React from 'react'

// Components
import HighlightedStoriesGridPost from 'components/elements/Stories/HighlightedStoriesGridPost'

// Third Party
import { useBlogBink } from '@ubo/blog-bink'
import styled from 'styled-components'
import { AnimatePresence } from 'framer-motion'
import { wrap } from 'popmotion'

const NoResult = styled.div`
  color: ${({ theme }) => theme.color.primary};
  font-family: ${({ theme }) => theme.font.family.secondary};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  text-align: center;
  font-size: 30px;
  padding: 70px 0;
`

interface HighlightedStoriesGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const variants = {
  enter: (direction: number) => ({
    x: direction > 0 ? 1000 : -1000,
    opacity: 0,
    transition: { x: { delay: 0.3 } },
  }),
  textEnter: (direction: number) => ({
    x: direction > 0 ? 300 : -300,
    opacity: 0,
  }),
  center: {
    zIndex: -1,
    x: 0,
    opacity: 1,
  },
  exit: {
    zIndex: -1,
    opacity: 0,
    position: 'absolute',
    transition: { opacity: { delay: 0.1 } },
  },
}

const HighlightedStoriesGrid: React.FC<HighlightedStoriesGridProps> = () => {
  const blogBink: any = useBlogBink()
  const [[page, direction], setPage] = React.useState([0, 0])

  const imageIndex = wrap(0, blogBink.posts.length, page)

  const paginate = (newDirection: number) => {
    setPage([page + newDirection, newDirection])
  }

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-9">
          {blogBink.posts.length !== 0 && (
            <AnimatePresence initial={false} custom={direction}>
              <HighlightedStoriesGridPost
                page={page}
                variants={variants}
                direction={direction}
                paginate={paginate}
                node={blogBink.posts[imageIndex].node}
              />
            </AnimatePresence>
          )}
        </div>
        {blogBink.posts.length === 0 && (
          <NoResult className="d-flex justify-content-center">
            Oeps, het lijkt erop dat wij nog geen artikelen hiervan hebben!
          </NoResult>
        )}
      </div>
    </div>
  )
}

export default HighlightedStoriesGrid
