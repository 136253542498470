import React from 'react'
import ParseContent from 'components/shared/ParseContent'

// Components
import Plaatjie from '@ubo/plaatjie'
import BreadCrumb from 'components/elements/BreadCrumb'
import FlexContentHandler from 'components/shared/FlexContentHandler'

// Third Party
import styled from 'styled-components'

interface BannerContactProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
  pageContext?: any
  location?: any
}

const StyledBannerContact = styled.section`
  margin-bottom: 2rem;

  @media (min-width: 992px) {
    margin-bottom: 4rem;
  }
`

const BannerWrapper = styled.div`
  position: relative;
  height: fit-content;
`

const ImageWrapper = styled.div`
  position: relative;
  height: 500px;
  z-index: -1;

  @media screen and (max-width: 991px) {
    height: 300px;
  }
`

const StyledImage = styled(Plaatjie)`
  height: 100%;
  border-bottom-left-radius: 180px;
  & img {
    @media (max-width: 575px) {
      object-fit: contain !important;
      min-height: 300px;
    }
  }

  @media screen and (max-width: 991px) {
    border-bottom-left-radius: 90px;
  }
`

const ContentWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  color: ${(props) => props.theme.color.grey};

  @media (min-width: 992px) {
    margin-top: -4rem;
  }
`

const Block = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  background-color: ${({ theme }) => theme.color.light};

  & h1,
  & h2,
  & h3 {
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-weight: ${({ theme }) => theme.font.weight.extraBold};
    color: ${({ theme }) => theme.color.darkish};
    font-size: 26px;
    line-height: 31px;
  }

  & p {
    font-size: 16px;
    line-height: 22px;
    font-weight: ${({ theme }) => theme.font.weight.light};
    text-align: justify;
    margin-bottom: 20px;
  }

  @media screen and (min-width: 992px) {
    margin-top: 0;
    margin-left: 100px;
    padding: 30px;
    max-width: 420px;
  }
`

const Content = styled(ParseContent)`
  background-color: ${({ theme }) => theme.color.light};
  border-radius: 20px;

  & p {
    margin-bottom: 0;
  }

  & table {
    width: 100% !important;
    margin-bottom: 1rem !important;

    & tr {
      height: auto !important;

      & td {
        vertical-align: top;
        height: auto !important;

        @media (max-width: 575px) {
          padding-bottom: 0 !important;
          display: flex;
          flex-direction: column;
          width: 100% !important;
        }
      }

      @media (max-width: 575px) {
        display: flex;
        flex-direction: column;
      }
    }

    & .socials {
      margin-bottom: 50px !important;

      & tr {
        align-items: center;
        margin-top: 20px;

        @media screen and (max-width: 991px) {
          margin-top: 0;
        }
      }

      & td {
        width: 14% !important;
      }

      & a {
        padding-right: 5px;
      }

      & a > img {
        min-width: 25px !important;
        min-height: 25px !important;
        transition: all 0.3s ease;

        &:hover {
          opacity: 0.7;
        }
      }

      @media (max-width: 575px) {
        position: absolute;
        border: 1px solid lightgrey;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
        padding: 1rem 1rem 1rem 0;
        right: 0;
        width: 20% !important;
      }
    }

    @media (max-width: 575px) {
      display: flex;
      flex-direction: column;
    }
  }

  & a {
    padding-right: 20px;

    &:hover {
      text-decoration: underline;
    }
    & > img {
      margin-right: 10px;
      height: 20px;
      width: 20px;
      &:hover {
        opacity: 0.5;
      }
    }
  }
  @media (max-width: 991px) {
    padding: 1rem 0 !important;
  }
`

const FormBlock = styled.div`
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  background-color: ${({ theme }) => theme.color.greyLight};
  padding: 2rem;

  & h1,
  & h2,
  & h3 {
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-weight: ${({ theme }) => theme.font.weight.extraBold};
    color: ${({ theme }) => theme.color.darkish};
    font-size: 26px;
    line-height: 31px;
  }

  & p {
    font-size: 16px;
    line-height: 22px;
    font-weight: ${({ theme }) => theme.font.weight.light};
    text-align: justify;
    margin-bottom: 20px;
  }

  @media screen and (min-width: 992px) {
    margin-top: 0;
    margin-right: 100px;
    padding: 30px;
    max-width: 450px;
  }
`

const ContactInfo = styled.div`
  color: #000;
  & a {
    font-weight: bold;
    color: ${({ theme }) => theme.color.primary};
  }

  .address {
    opacity: 0.8;
  }
`

const Hours = styled(ParseContent)`
  color: #000;
`

const MapsWrapper = styled.div``

const BannerContact: React.FC<BannerContactProps> = ({
  pageContext,
  fields,
  location,
}) => {
  const { contactinfo } = fields
  return (
    <>
      <StyledBannerContact>
        <BannerWrapper>
          <ImageWrapper className="d-flex justify-content-center">
            <StyledImage loading="eager" alt="banner" image={fields?.image} />
          </ImageWrapper>
          <ContentWrapper>
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <Block className="p-4">
                    <BreadCrumb label="" pageContext={pageContext} />
                    <ParseContent content={fields.description} />
                  </Block>
                  <div className="mt-lg-5 mt-3 row justify-content-center">
                    <div className="col-lg-8">
                      <ContactInfo className="row">
                        <div className="col-lg-6">
                          <Content
                            content={contactinfo?.address}
                            className="address"
                          />
                          <div>
                            <a
                              href={contactinfo?.email?.url}
                              className="font-weight-bold"
                            >
                              {contactinfo?.email?.title}
                            </a>
                          </div>
                          <div>
                            <a href={contactinfo?.phone?.url} className="phone">
                              {contactinfo?.phone?.title}
                            </a>
                          </div>
                          <div className="col-3 col-lg-8 d-flex justify-content-between mt-3">
                            {contactinfo?.socials?.map((social, index) => (
                              <a href={social?.link?.url} key={index}>
                                <Plaatjie image={social?.icon} alt="Social" />
                              </a>
                            ))}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          {contactinfo?.emailadresses?.map((email, index) => (
                            <div key={index}>
                              <div>
                                <span className="email-title">
                                  {email?.title}
                                </span>
                              </div>
                              <div>
                                <a href={email?.link?.url}>
                                  {email?.link?.title}
                                </a>
                              </div>
                            </div>
                          ))}
                        </div>
                      </ContactInfo>
                      <div className="row mt-5 mb-5 mb-lg-0">
                        <Hours content={contactinfo?.openinghours} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <FormBlock>
                    <FlexContentHandler
                      prefix="Fragment_FlexFragment"
                      fields={fields.element?.flex_fragment}
                      location={location}
                      pageContext={pageContext}
                    />
                  </FormBlock>
                </div>
              </div>
            </div>
          </ContentWrapper>
        </BannerWrapper>
      </StyledBannerContact>
      <MapsWrapper className="container pb-5">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <iframe
              title="Veerman ICT google maps"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9927.983044735349!2d4.450850797213959!3d51.531637580311!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c416c39e1139e5%3A0x4f63db02b9405dfc!2sBadhuisstraat%2018%2C%204703%20BH%20Roosendaal!5e0!3m2!1snl!2snl!4v1626695369808!5m2!1snl!2snl"
              width="100%"
              height="350"
              style={{ border: 0 }}
              allowFullScreen
              loading="lazy"
            />
          </div>
        </div>
      </MapsWrapper>
    </>
  )
}

export default BannerContact
