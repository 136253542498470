/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
export const encodeQueryData = (data: Array<string>) => {
  const ret = []
  for (const d in data)
    ret.push(`${encodeURIComponent(d)}=${encodeURIComponent(data[d])}`)
  return ret.join('&')
}

export const shuffle = (array: any) => {
  let currentIndex = array.length
  let randomIndex

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1

    // And swap it with the current element.
    ;[array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ]
  }

  return array
}

export const chunkArray = (array: Array<Object>, size: number) => {
  const chunkedArray = []
  // @ts-ignore
  const copied = [...array] // ES6 destructuring
  const numOfChild = Math.ceil(copied.length / size) // Round up to the nearest integer

  for (let i = 0; i < numOfChild; i += 1) {
    chunkedArray.push(copied.splice(0, size))
  }

  return chunkedArray
}

export const calculateColumn = (length: number) => 12 / length
