import React from 'react'

// Third Party
import styled, { css } from 'styled-components'
import { useBlogBink, BlogBinkFilter } from '@ubo/blog-bink'

const StyledOtherStoriesFilter = styled(BlogBinkFilter)<{ selected: boolean }>`
  border: 1px solid ${({ theme }) => theme.color.primary};
  min-width: 150px;
  border-radius: 65px;
  padding: 10px;
  transition: all 0.3s ease;
  font-family: ${({ theme }) => theme.font.family.secondary};

  &:hover {
    background-color: ${({ theme }) => theme.color.primaryLight};
  }

  ${({ selected }) =>
    selected &&
    css`
      background-color: ${({ theme }) => theme.color.primary};
      color: ${({ theme }) => theme.color.light};
    `};

  @media screen and (max-width: 991px) {
    min-width: 100px;
    padding: 5px 10px;
  }
`

interface PostFiltersProps {}

interface PostFilterProps {
  node: {
    id: string
    name: string
  }
}

const PostFilters: React.FC<PostFiltersProps> = () => {
  const blogBink = useBlogBink()

  return (
    <div className="container d-flex pb-5">
      {blogBink.categories.map((category: PostFilterProps) => (
        <StyledOtherStoriesFilter
          key={category.node.id}
          id={category.node.id}
          selected={blogBink.isSelected(category.node.id)}
          className="me-lg-3 me-2"
        >
          {category.node.name}
        </StyledOtherStoriesFilter>
      ))}
    </div>
  )
}

export default PostFilters
