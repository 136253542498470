import React from 'react'

// Components
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'
import HighlightedVacanciesGridPost from 'components/elements/Vacancies/HighlightedVacanciesGridPost'

// Third Party
import { useBlogBink, BlogBinkButton } from '@ubo/blog-bink'
import styled from 'styled-components'

interface HighlightedVacanciesGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const GridWrapper = styled.div``

const HighlightedVacanciesGrid: React.FC<HighlightedVacanciesGridProps> = ({
  fields,
}) => {
  const blogBink = useBlogBink()

  return (
    <div className="container">
      <GridWrapper className="row justify-content-center">
        {blogBink.posts.map((post) => {
          const { node }: any = post

          return (
            <div key={post.node.id} className="col-12 col-lg-8 mb-4">
              <HighlightedVacanciesGridPost node={node} blogFields={fields} />
            </div>
          )
        })}
      </GridWrapper>

      {blogBink.showMoreButton && (
        <div className="mt-5 text-center">
          <ButtonPrimary to="/" isCustom>
            <BlogBinkButton>{fields.loadmoretext}</BlogBinkButton>
          </ButtonPrimary>
        </div>
      )}
    </div>
  )
}

export default HighlightedVacanciesGrid
