import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

// Third Party
import BlogBink, { BlogBinkPosts } from '@ubo/blog-bink'

// Components
import HighlightedStoriesGrid from 'components/elements/Stories/HighlightedStoriesGrid'
import Title from 'components/elements/Title'

interface HighlightedStoriesProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
  location: any
}

const HighlightedStories: React.FC<HighlightedStoriesProps> = ({ fields }) => {
  const {
    allWpPost,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.HighlightedStoriesQueryQuery>(graphql`
    query HighlightedStoriesQuery {
      allWpPost(
        filter: {
          categories: {
            nodes: { elemMatch: { slug: { eq: "klantverhalen" } } }
          }
        }
        sort: { order: DESC, fields: date }
      ) {
        edges {
          node {
            ...generalPostFragment
          }
        }
      }
    }
  `)

  const posts: unknown = allWpPost.edges

  const givenIds: any = fields.highlighted?.map((h) => h?.databaseId)

  return (
    <section>
      <BlogBink
        posts={posts as BlogBinkPosts}
        showIds={givenIds}
        id="highlighted_stories"
      >
        <div className="py-5">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-9">
                <div className="d-flex justify-content-end">
                  <Title>
                    {givenIds.length > 1 ? `klantverhalen` : `klantverhaal`}
                  </Title>
                </div>
              </div>
            </div>
          </div>
          <HighlightedStoriesGrid fields={fields} />
        </div>
      </BlogBink>
    </section>
  )
}

export default HighlightedStories
