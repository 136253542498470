import React from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'
import { ButtonPrimaryCSS } from 'components/elements/Buttons/ButtonPrimary'
import { ButtonSecondaryCSS } from 'components/elements/Buttons/ButtonSecondary'
import FormDuuf from 'components/related/Form'

// Hooks
import useVacancy from 'hooks/useVacancy'

// Third Party
import styled, { css } from 'styled-components'

const StyledFormDuuf = styled(FormDuuf)`
  position: relative;

  & .form-duuf-group {
    margin-bottom: 1.5rem;
    /* max-width: 400px; */
    position: relative;

    &-fileupload {
      display: flex;
      justify-content: flex-end;
      background-color: ${({ theme }) => theme.color.light};
      border-radius: 10px;
      padding: 10px;

      &::before {
        content: 'Upload je CV';
        position: absolute;
        font-size: 16px;
        color: ${({ theme }) => theme.color.grey};
        top: 13px;
        left: 15px;
      }
    }
  }

  & .form-duuf-textarea,
  & .form-duuf-input {
    width: 100%;
    border-radius: 10px;
    border: none;
    min-height: 50px;
    padding: 0 15px;
    font-size: 16px;

    &::placeholder {
      color: ${({ theme }) => theme.color.grey};
    }
  }

  & .form-duuf-textarea {
    padding: 15px;
    height: 160px;
  }

  & .form-duuf-file-button {
    ${ButtonSecondaryCSS};

    & button {
      font-size: 12px;
      border-radius: 5px;
      font-weight: 200;
      padding: 8px 12px;
      min-width: unset;
    }
  }

  & .form-duuf-file-name {
    margin-left: 0.5rem;
  }

  & .form-duuf-checkbox-label {
    margin-left: 0.5rem;
    margin-bottom: 0;
  }

  & .form-duuf-label-mandatory {
    color: ${({ theme }) => theme.color.secondary};
  }

  & .form-duuf-error {
    color: tomato;
    position: absolute;
    right: 15px;
    top: 13px;
    font-size: 14px;

    & + input,
    + textarea {
      border: 1px solid tomato;
    }
  }

  & .form-duuf-submit {
    ${ButtonPrimaryCSS};

    &-disabled {
      opacity: 0.5;
    }
  }

  & .form-duuf-confirm {
    color: ${({ theme }) => theme.color.primary};
    font-size: 20px;
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }

  & .form-duuf-something-wrong {
    color: tomato;
  }

  & .form-duuf-loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.5);
  }
`

interface FormApplyProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Form
  location?: any
}

const StyledFormApply = styled.section<{ focused: boolean }>`
  background-color: ${({ theme }) => theme.color.greyLight};
  padding: 30px 30px 30px 30px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  border-radius: 25px;

  ${({ focused }) =>
    focused &&
    css`
      position: relative;
      z-index: 10;
    `}

  & h1,
  h2,
  h3,
  p {
    font-size: 20px;
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    color: ${({ theme }) => theme.color.darkish};
  }

  @media screen and (min-width: 992px) {
    margin-top: -300px;
  }
`

const Shadow = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: ${({ theme }) => theme.color.darker};
  opacity: 0.5;
  z-index: 2;
  cursor: pointer;
`

const FormApply: React.FC<FormApplyProps> = ({ location, fields }) => {
  const vacancy: any = useVacancy()
  const [focused, setFocus] = React.useState(false)

  React.useEffect(() => {
    if (location.hash === '#solliciteer') {
      if (typeof document !== 'undefined') {
        document.getElementById('input_3')?.focus()
        setFocus(true)
      }
    }
  }, [])

  return (
    <>
      {focused && <Shadow onClick={() => setFocus(false)} />}
      <StyledFormApply focused={focused} className="mb-5">
        <ParseContent content={fields.description} />
        <StyledFormDuuf
          defaultFields={{ input_7: vacancy.title }}
          id={Number(fields.formid) || 2}
          className="mt-4 pt-2"
        />
      </StyledFormApply>
    </>
  )
}

export default FormApply
