import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

// Elements
import BlogBink, { BlogBinkPosts } from '@ubo/blog-bink'
import PostGrid from 'components/elements/Posts/PostGrid'
import Title from 'components/elements/Title'

// Third Party
import styled from 'styled-components'
import ButtonTransparent from 'components/elements/Buttons/ButtonTransparent'

interface PreviewKnowledgeBaseProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
  location: any
}

const StyledButtonTransparent = styled(ButtonTransparent)`
  @media screen and (max-width: 991px) {
    margin-bottom: 5px;
  }
`

const PreviewKnowledgeBase: React.FC<PreviewKnowledgeBaseProps> = ({
  fields,
}) => {
  const {
    allWpPost,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.PreviewknowledgeBaseQueryQuery>(graphql`
    query PreviewknowledgeBaseQuery {
      allWpPost(
        sort: { order: DESC, fields: date }
        filter: {
          categories: {
            nodes: { elemMatch: { name: { ne: "Klantverhalen" } } }
          }
        }
      ) {
        edges {
          node {
            ...generalPostFragment
          }
        }
      }
    }
  `)

  const posts: unknown = allWpPost.edges

  return (
    <section>
      <BlogBink
        posts={posts as BlogBinkPosts}
        id="preview_knowledge_base"
        limit={3}
      >
        <div className="py-5">
          <div className="container">
            <div className="d-flex flex-column flex-sm-row justify-content-between align-items-end">
              <Title>kennisbank</Title>
              <StyledButtonTransparent to="/kennisbank">
                {fields.readmoretext}
              </StyledButtonTransparent>
            </div>
          </div>
          <PostGrid fields={fields} />
        </div>
      </BlogBink>
    </section>
  )
}

export default PreviewKnowledgeBase
