import React from 'react'

// Images
import Pattern from 'img/pattern.inline.svg'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'

interface WithPatternProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
  location?: any
}

const PatternWrapper = styled.div`
  position: absolute;
  top: 0;
  z-index: -1;

  @media screen and (max-width: 991px) {
    opacity: 0.4;
  }
`

const Content = styled(ParseContent)`
  & h2 {
    font-size: 22px;
    line-height: 26px;
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    color: ${({ theme }) => theme.color.dark};
    padding-bottom: 15px;
  }

  & p {
    color: ${({ theme }) => theme.color.grey};
    font-size: 16px;
    line-height: 26px;
  }

  & img {
    width: 100%;
    height: auto;
  }
`

const WithPattern: React.FC<WithPatternProps> = ({ fields }) => (
  <section className="position-relative py-lg-5">
    <div className="container pb-lg-0 pb-3 pt-lg-5 pt-3">
      <div className={`row py-lg-4 pt-5 justify-content-${fields.alignment}`}>
        <div className={`col-lg-${fields.width}`}>
          <Content content={fields.description} />
        </div>
        <div className={`col-lg-${12 - (fields.width || 11)}`}>
          <PatternWrapper>
            <Pattern />
          </PatternWrapper>
        </div>
      </div>
    </div>
  </section>
)

export default WithPattern
