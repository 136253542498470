import React from 'react'

// Components
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'

// Third Party
import styled from 'styled-components'

interface HighlightedVacancyGridPostProps {
  // eslint-disable-next-line
  blogFields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
  node: GatsbyTypes.WpVacancy
}

const Block = styled.div`
  background-color: ${({ theme }) => theme.color.light};
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  border-radius: 25px;
  padding: 30px;

  & h2 {
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-size: 28px;
    line-height: 33px;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 991px) {
    padding: 20px 15px;
  }
`

const MetaPoint = styled.div`
  display: flex;
  align-items: center;
  padding-left: 15px;
  position: relative;
  font-weight: ${({ theme }) => theme.font.weight.light};
  font-size: 14px;
  color: ${({ theme }) => theme.color.grey};

  &::before {
    content: '';
    position: absolute;
    height: 10px;
    width: 10px;
    background-color: ${({ theme }) => theme.color.primary};
    border-radius: 5px;
    left: 0;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
  }
`

const HighlightedVacancyGridPost: React.FC<HighlightedVacancyGridPostProps> = ({
  node,
  blogFields,
}) => (
  <Block className="d-flex flex-lg-nowrap flex-wrap justify-content-between align-items-center">
    <div className="w-100">
      <h2>{node.title}</h2>
      <div className="d-flex">
        <MetaPoint className="me-4">
          {node.vacancyrecap?.vacancymeta?.level}
        </MetaPoint>
        <MetaPoint className="me-4">
          {node.vacancyrecap?.vacancymeta?.timechoice}
        </MetaPoint>
        <MetaPoint>{node.vacancyrecap?.vacancymeta?.location}</MetaPoint>
      </div>
    </div>
    <div className="d-flex justify-content-end pt-lg-0 pt-3 w-100">
      <ButtonPrimary to={node.uri || '/'}>
        {blogFields.readmoretext}
      </ButtonPrimary>
    </div>
  </Block>
)

export default HighlightedVacancyGridPost
