import React from 'react'
import { navigate } from 'gatsby'

// Components
import ParseContent from 'components/shared/ParseContent'
import BreadCrumb from 'components/elements/BreadCrumb'

// Third Party
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Hooks
import useVacancy from 'hooks/useVacancy'

interface BannerVacancyProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
  pageContext?: any
}

const StyledBannerVacancy = styled.section``

const BannerWrapper = styled.div`
  position: relative;
  height: fit-content;
`

const ImageWrapper = styled.div`
  position: relative;
  height: 500px;
  z-index: -1;
  margin-bottom: 150px;

  @media screen and (max-width: 1199px) {
    margin-bottom: 20px;
  }
`

const StyledImage = styled(Plaatjie)`
  height: 100%;
  border-bottom-left-radius: 180px;

  @media screen and (max-width: 991px) {
    border-bottom-left-radius: 90px;
  }
`

const ContentWrapper = styled.div`
  position: absolute;
  bottom: 80px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  padding-top: 350px;

  color: ${(props) => props.theme.color.grey};

  @media screen and (max-width: 991px) {
    position: relative;
    padding-top: 0;
    bottom: 0;
    margin-top: -100px;
  }
`

const Block = styled.div`
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  background-color: ${({ theme }) => theme.color.light};

  & h1,
  h2,
  h3 {
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-weight: ${({ theme }) => theme.font.weight.extraBold};
    color: ${({ theme }) => theme.color.darkish};
    font-size: 26px;
    line-height: 31px;
  }

  & p {
    font-size: 16px;
    line-height: 22px;
    font-weight: ${({ theme }) => theme.font.weight.light};
    text-align: justify;
    margin-bottom: 20px;
  }

  @media screen and (min-width: 1200px) {
    margin-top: 60px;
    margin-left: 100px;
    padding: 30px;
    max-width: 520px;
  }

  @media screen and (max-width: 1199px) {
    padding: 30px;
  }
`

const ApplyButton = styled.div`
  margin-right: 5px;
  width: 100%;
  background-color: ${({ theme }) => theme.color.primary};
  color: ${({ theme }) => theme.color.light};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 62px;
  padding: 12px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => theme.color.primaryDark};
  }
`

// const InfoButton = styled.div`
//   margin-left: 5px;
//   width: 100%;
//   background-color: ${({ theme }) => theme.color.light};
//   color: ${({ theme }) => theme.color.primary};
//   border: 1px solid ${({ theme }) => theme.color.primary};
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   border-radius: 62px;
//   padding: 12px;
// `

const MetaPoint = styled.div`
  padding-left: 15px;
  position: relative;
  font-weight: ${({ theme }) => theme.font.weight.light};
  font-size: 18px;

  &::before {
    content: '';
    position: absolute;
    height: 10px;
    width: 10px;
    background-color: ${({ theme }) => theme.color.primary};
    border-radius: 5px;
    left: 0;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
  }
`

const BannerVacancy: React.FC<BannerVacancyProps> = ({
  pageContext,
  fields,
}) => {
  // eslint-disable-next-line
  const vacancy: { vacancyrecap?: GatsbyTypes.WpVacancy_Vacancyrecap } =
    useVacancy()

  return (
    <StyledBannerVacancy>
      <BannerWrapper>
        <ImageWrapper className="d-flex justify-content-center">
          <StyledImage loading="eager" alt="banner" image={fields?.image} />
        </ImageWrapper>
        <ContentWrapper>
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <Block>
                  <BreadCrumb label="" pageContext={pageContext} />
                  <ParseContent content={fields.description} />
                  <div className="d-flex pt-3">
                    <MetaPoint className="me-4">
                      {vacancy.vacancyrecap?.vacancymeta?.level}
                    </MetaPoint>
                    <MetaPoint className="me-4">
                      {vacancy.vacancyrecap?.vacancymeta?.timechoice}
                    </MetaPoint>
                    <MetaPoint>
                      {vacancy.vacancyrecap?.vacancymeta?.location}
                    </MetaPoint>
                  </div>
                  <div className="pt-4 mt-3 d-flex justify-content-between">
                    <ApplyButton onClick={() => navigate('#solliciteer')}>
                      Solliciteer direct
                    </ApplyButton>
                    {/* <InfoButton>Meer informatie</InfoButton> */}
                  </div>
                </Block>
              </div>
            </div>
          </div>
        </ContentWrapper>
      </BannerWrapper>
    </StyledBannerVacancy>
  )
}

export default BannerVacancy
