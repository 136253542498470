import React from 'react'
import { navigate } from 'gatsby'

// Components
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

interface BannerHomeProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
  location?: any
}

const StyledBannerHome = styled.section``

const BannerWrapper = styled.div`
  position: relative;
  height: fit-content;

  @media screen and (max-width: 991px) {
    padding-bottom: 0px;
  }
`

const ImageWrapper = styled.div`
  position: relative;
  height: 800px;
  z-index: -1;

  @media screen and (max-width: 991px) {
    height: 500px;
  }
`

const StyledImage = styled(Plaatjie)`
  height: 100%;
  border-bottom-left-radius: 180px;

  @media screen and (max-width: 991px) {
    border-bottom-left-radius: 90px;
  }
`

const ContentWrapper = styled.div`
  position: absolute;
  bottom: 80px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  padding-top: 350px;

  color: ${(props) => props.theme.color.grey};

  @media screen and (max-width: 991px) {
    padding-top: 0;
    margin-top: -360px;
    bottom: 0;
    position: relative;
  }
`

const Block = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  background-color: ${({ theme }) => theme.color.light};

  & h2,
  h3 {
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-weight: ${({ theme }) => theme.font.weight.extraBold};
    color: ${({ theme }) => theme.color.darkish};
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 10px;
  }

  & p {
    font-size: 16px;
    line-height: 22px;
    font-weight: ${({ theme }) => theme.font.weight.light};
    text-align: justify;
    margin-bottom: 20px;
  }

  @media screen and (min-width: 992px) {
    margin-top: 100px;
    padding: 30px;
    max-width: 420px;
  }

  @media screen and (max-width: 991px) {
    margin-top: 20px;
    padding: 25px 20px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);

    & p {
      padding-right: 20px;
    }
  }
`

const Float = styled.div`
  & h1,
  h2,
  h3 {
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-weight: ${({ theme }) => theme.font.weight.extraBold};
    color: ${({ theme }) => theme.color.darkish};
    font-size: 56px;
    line-height: 66px;
  }

  @media screen and (min-width: 992px) {
    padding-left: 150px;
  }

  @media screen and (max-width: 991px) {
    position: relative;
    z-index: 90;
  }
`

const BecomeOne = styled.div`
  position: fixed;
  right: -90px;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 9999;
  pointer-events: none;
  bottom: 150px;

  & > div {
    pointer-events: auto;
    transform: rotate(270deg);
    width: 230px;
    background-color: ${({ theme }) => theme.color.primary};
    display: flex;
    padding: 15px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    justify-content: center;
    color: ${({ theme }) => theme.color.light};
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-size: 20px;
    line-height: 24px;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    cursor: pointer;
    transition: all 0.3s ease;
    z-index: 9999;
    position: relative;
    opacity: 0.9;

    &:hover {
      background-color: ${({ theme }) => theme.color.primaryDark};
      opacity: 1;
    }
  }

  @media screen and (max-width: 991px) {
    right: -80px;

    & > div {
      font-size: 16px;
      line-height: 20px;
      padding: 10px 0px;
      width: 200px;
    }
  }

  @media screen and (max-width: 575px) {
    right: -90px;
    bottom: -250px;
    & > div {
      font-size: 12px;
      line-height: 20px;
      padding: 4px 0px;
      width: 200px;
      opacity: 0.85;
    }
  }
`

const BannerHome: React.FC<BannerHomeProps> = ({ fields }) => (
  <StyledBannerHome>
    <BecomeOne>
      <div
        role="button"
        tabIndex={-100}
        onKeyDown={() => navigate('/carriere/vacatures')}
        onClick={() => navigate('/carriere/vacatures')}
      >
        Word een Veerman
      </div>
    </BecomeOne>
    <BannerWrapper>
      <ImageWrapper className="d-flex justify-content-center">
        <StyledImage loading="eager" alt="banner" image={fields?.image} />
      </ImageWrapper>
      <ContentWrapper>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <Float>
                <ParseContent content={fields?.sidetext} />
              </Float>
            </div>
            <div className="col-lg-6">
              <Block>
                <ParseContent content={fields?.description} />
              </Block>
            </div>
          </div>
        </div>
      </ContentWrapper>
    </BannerWrapper>
  </StyledBannerHome>
)

export default BannerHome
