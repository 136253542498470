import React from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'

interface SupportProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
}

const Block = styled.div`
  border-radius: 25px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  padding: 40px 30px;
  position: relative;
  left: 140px;
  max-width: 645px;
  margin: 150px 0 100px 0;
  background-color: ${({ theme }) => theme.color.light};

  & p,
  li {
    color: ${({ theme }) => theme.color.grey};
  }

  & li {
    list-style: none;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 9px;
      left: -20px;
      background-color: ${({ theme }) => theme.color.primary};
      height: 8px;
      width: 8px;
      border-radius: 4px;
    }
  }

  & h2 {
    font-size: 28px;
    line-height: 33px;
  }

  @media screen and (max-width: 991px) {
    left: 0;
    margin: 100px 0 0 0;
  }

  @media screen and (max-width: 350px) {
    margin: 60px 0 0 0;
    padding: 30px 20px;
  }
`

const BackgroundText = styled(ParseContent)`
  position: absolute;
  z-index: -1;
  top: -95px;
  left: -280px;

  & p {
    font-size: 100px;
    font-weight: ${({ theme }) => theme.font.weight.extraBold};
    line-height: 118px;
    color: ${({ theme }) => theme.color.dark};
    opacity: 0.15;
    font-family: ${({ theme }) => theme.font.family.secondary};
  }

  @media screen and (max-width: 991px) {
    top: -170px;
    left: 15px;

    & p {
      line-height: 90px;
    }
  }

  @media screen and (max-width: 575px) {
    top: -130px;

    & p {
      font-size: 70px;
      line-height: 70px;
    }
  }

  @media screen and (max-width: 350px) {
    top: -100px;

    & p {
      font-size: 50px;
      line-height: 50px;
    }
  }
`

const Support: React.FC<SupportProps> = ({ fields }) => (
  <section>
    <div className="container py-5">
      <div className="d-flex position-relative justify-content-center">
        <Block>
          <BackgroundText content={fields.backgroundtext} />
          <ParseContent content={fields.description} />
        </Block>
      </div>
    </div>
  </section>
)

export default Support
