import React from 'react'
import Text from 'components/flex/Text/Text'
import WithFragments from 'components/flex/Text/WithFragmentsFlex'
import WithPattern from 'components/flex/Text/WithPattern'
import Support from 'components/flex/Text/Support'
import WithSidebar from './WithSidebar'

interface TextShellProps {
  fields: any
  location?: any
  pageContext?: any
}

interface TextProps {
  [key: string]: any
}

const TextShell: React.FC<TextShellProps> = ({
  fields,
  location = {},
  pageContext,
}) => {
  const types: TextProps = {
    normal: Text,
    withFragments: WithFragments,
    with_pattern: WithPattern,
    support: Support,
    withSidebar: WithSidebar
  }

  if (!fields.styletype || !types[fields.styletype]) {
    return null
  }

  const Component = types[fields.styletype]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
      pageContext={pageContext}
    />
  )
}

export default TextShell
