import React from 'react'
import ParseContent from 'components/shared/ParseContent'

// Components
import Plaatjie from '@ubo/plaatjie'

// Third Party
import styled from 'styled-components'
import BreadCrumb from 'components/elements/BreadCrumb'

interface BannerDefaultProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
  pageContext?: any
}

const StyledBannerDefault = styled.section``

const BannerWrapper = styled.div`
  position: relative;
  height: fit-content;
`

const ImageWrapper = styled.div`
  position: relative;
  height: 500px;
  z-index: -1;

  @media screen and (max-width: 991px) {
    height: 300px;
  }
`

const StyledImage = styled(Plaatjie)`
  height: 100%;
  border-bottom-left-radius: 180px;

  @media screen and (max-width: 991px) {
    border-bottom-left-radius: 90px;
  }
`

const ContentWrapper = styled.div`
  position: absolute;
  bottom: -20px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 10;
  color: ${(props) => props.theme.color.grey};
`

const Block = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  background-color: ${({ theme }) => theme.color.light};

  & h1,
  h2,
  h3 {
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-weight: ${({ theme }) => theme.font.weight.extraBold};
    color: ${({ theme }) => theme.color.darkish};
    font-size: 26px;
    line-height: 31px;
  }

  & p {
    font-size: 16px;
    line-height: 22px;
    font-weight: ${({ theme }) => theme.font.weight.light};
    text-align: justify;
    margin-bottom: 20px;
  }

  & a {
    margin-top: 15px;
  }

  @media screen and (min-width: 992px) {
    margin-top: 60px;
    margin-left: 100px;
    padding: 30px;
    max-width: 420px;
  }

  @media screen and (max-width: 991px) {
    padding: 20px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
  }
`

const BannerDefault: React.FC<BannerDefaultProps> = ({
  pageContext,
  fields,
}) => (
  <StyledBannerDefault>
    <BannerWrapper>
      <ImageWrapper className="d-flex justify-content-center">
        <StyledImage loading="eager" alt="banner" image={fields?.image} />
      </ImageWrapper>
      <ContentWrapper>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <Block>
                <BreadCrumb label="" pageContext={pageContext} />
                <ParseContent content={fields?.description} />
              </Block>
            </div>
          </div>
        </div>
      </ContentWrapper>
    </BannerWrapper>
  </StyledBannerDefault>
)

export default BannerDefault
