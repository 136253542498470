import React from 'react'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'

// Third Party
import styled from 'styled-components'

interface BreadCrumbProps {
  pageContext: any
  label: string
}

const BreadcrumbContainer = styled.div`
  & ol {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
  }

  & li {
    list-style: none;
  }

  & a {
    text-transform: inherit;
    font-size: 16px !important;
    line-height: 21px;
    color: ${({ theme }) => theme.color.grey} !important;

    &[aria-current='page'] {
      color: ${({ theme }) => theme.color.primary} !important;
    }
  }

  .breadcrumb__separator {
    margin: 0 6px;
  }
`

const BreadCrumb: React.FC<BreadCrumbProps> = ({ pageContext, label }) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext

  crumbs.forEach((element: any) => {
    // eslint-disable-next-line no-param-reassign
    element.crumbLabel = element.crumbLabel.replace(/-/g, ' ')
  })

  return (
    <BreadcrumbContainer>
      <Breadcrumb crumbs={crumbs} crumbSeparator=" > " crumbLabel={label} />
    </BreadcrumbContainer>
  )
}

export default BreadCrumb
