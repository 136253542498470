import React from 'react'

// Images
import check from 'img/check.svg'

// Components
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled, { css } from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

interface ImageWithBlockWithoutTitleProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Components
}

const ImageWrapper = styled.div`
  position: relative;
  height: fit-content;
  width: 100%;
  border-radius: 25px;
  /* margin-bottom: 240px; */
  max-width: 580px;

  & img {
    height: 385px;
    border-radius: 25px;
  }

  @media screen and (max-width: 991px) {
    height: auto;
    margin-bottom: 0;
  }
`

const Block = styled.div<{ left: boolean }>`
  background-color: ${({ theme }) => theme.color.light};
  position: relative;
  padding: 40px 40px 50px 40px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  border-radius: 25px;
  max-width: 600px;

  ${({ left }) =>
    left
      ? css`
          margin-top: -200px;
          margin-left: -400px;
        `
      : css`
          margin-top: -180px;
          margin-right: -290px;
        `}

  & h2 {
    font-size: 22px;
    line-height: 26px;
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    padding-bottom: 20px;
  }

  & ul {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  & li {
    list-style: none;
    line-height: 40px;
    font-size: 18px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      background-image: url(${check});
      background-size: contain;
      background-repeat: no-repeat;
      height: 15px;
      width: 20px;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      left: -30px;
    }
  }

  & p {
    color: ${({ theme }) => theme.color.grey};
  }

  @media screen and (max-width: 991px) {
    position: relative;
    width: 90%;
    padding: 20px 20px 20px 20px;

    ${({ left }) =>
      left
        ? css`
            bottom: 0;
            margin-top: -100px;
            left: 2%;
          `
        : css`
            bottom: 0;
            margin-top: -100px;
            right: 2%;
          `}
  }
`

const ImageWithBlockWithoutTitle: React.FC<ImageWithBlockWithoutTitleProps> = ({
  fields,
}) => (
  <section>
    <div className="container py-lg-5 py-3">
      {fields.position === 'left' && (
        <div className="row justify-content-center">
          <div className="col-lg-1" />
          <div className="col-lg-6">
            <ImageWrapper>
              <Plaatjie image={fields.image} alt="" />
              <Block left={false}>
                <ParseContent content={fields.description} />
              </Block>
            </ImageWrapper>
          </div>
          <div className="col-lg-2" />
        </div>
      )}
      {fields.position === 'right' && (
        <div className="row justify-content-center">
          <div className="col-lg-2" />
          <div className="col-lg-7 d-flex justify-content-end">
            <ImageWrapper>
              <Plaatjie image={fields.image} alt="" />
              <Block left>
                <ParseContent content={fields.description} />
              </Block>
            </ImageWrapper>
          </div>
        </div>
      )}
    </div>
  </section>
)

export default ImageWithBlockWithoutTitle
