import React from 'react'

// Third Party
import styled from 'styled-components'

interface TitleProps {
  children?: any
  className?: string
}

const StyledTitle = styled.div`
  font-size: 100px;
  line-height: 94px;
  font-family: ${({ theme }) => theme.font.family.secondary};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  color: ${({ theme }) => theme.color.dark};
  opacity: 0.15;
  z-index: -1;
  position: relative;

  @media screen and (min-width: 992px) {
    font-size: 100px;
    line-height: 94px;
    margin-bottom: -15px !important;
  }

  @media screen and (max-width: 991px) {
    font-size: 45px;
    line-height: 45px;
    margin-bottom: -5px !important;
    margin-left: 20px;
  }

  @media screen and (max-width: 575px) {
    font-size: 36px;
    line-height: 45px;
    margin-bottom: -5px !important;
    margin-left: 20px;
  }

  & p,
  & h1,
  & h2,
  & h3 {
    font-size: 100px;
    line-height: 94px;
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    color: ${({ theme }) => theme.color.dark};
    margin-bottom: -15px !important;

    @media screen and (max-width: 991px) {
      font-size: 45px;
      line-height: 45px;
      margin-bottom: -5px !important;
      margin-left: 20px;
    }
  }
`

const Title: React.FC<TitleProps> = ({ children, className }) => (
  <StyledTitle className={className}>{children}</StyledTitle>
)

export default Title
