import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

// Third Party
import BlogBink, { BlogBinkPosts } from '@ubo/blog-bink'

// Components
import PostGrid from 'components/elements/Posts/PostGrid'

interface StoriesProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
  location: any
}

const Stories: React.FC<StoriesProps> = ({ fields }) => {
  const {
    allWpPost,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.StoriesQueryQuery>(graphql`
    query StoriesQuery {
      allWpPost(
        filter: {
          categories: {
            nodes: { elemMatch: { slug: { eq: "klantverhalen" } } }
          }
        }
        sort: { order: DESC, fields: date }
      ) {
        edges {
          node {
            ...generalPostFragment
          }
        }
      }
    }
  `)

  const posts: unknown = allWpPost.edges

  return (
    <section>
      <BlogBink
        posts={posts as BlogBinkPosts}
        id="stories"
        limit={Number(fields.limit)}
      >
        <div className="pt-5">
          <PostGrid fields={fields} />
        </div>
      </BlogBink>
    </section>
  )
}

export default Stories
