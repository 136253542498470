/* eslint-disable react/no-array-index-key */
import React from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

// Third Party
import styled from 'styled-components'

interface VisionBlockSmallProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Components
}

const Title = styled(ParseContent)`
  & p {
    font-size: 30px;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    color: ${({ theme }) => theme.color.primary};
  }
`

const Content = styled(ParseContent)`
  & p {
    font-size: 16px;
    color: ${({ theme }) => theme.color.grey};
  }
`

const Block = styled.div`
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
  border-radius: 25px;
  padding: 10px 20px 10px 20px;

  margin-bottom: 45px;

  &:last-of-type {
    margin-bottom: 0;
  }
`

const StyledPlaatjie = styled(Plaatjie)`
  height: 80px;
  width: 80px;
  position: absolute;
  right: -20px;
  top: -30px;

  @media screen and (max-width: 575px) {
    height: 50px;
    width: 50px;
    top: -20px;
    right: 10px;
  }
`

const VisionBlockSmall: React.FC<VisionBlockSmallProps> = ({ fields }) => (
  <section>
    <div className="py-5">
      {fields.visionitems?.map((v, index) => (
        <Block key={index} className="position-relative">
          <StyledPlaatjie
            alt=""
            image={{
              url: v?.icon?.localFile?.publicURL,
              subtype: 'svg+xml',
            }}
          />
          <Title content={v?.title} />
          <Content content={v?.description} />
        </Block>
      ))}
    </div>
  </section>
)

export default VisionBlockSmall
