import React from 'react'

// Elements
import ParseContent from 'components/shared/ParseContent'
import FlexContentHandler from 'components/shared/FlexContentHandler'
import Share from 'components/elements/Share'

// Third Party
import styled from 'styled-components'
import usePost from 'hooks/usePost'
import Plaatjie from '@ubo/plaatjie'

interface WithFragmentsProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
  location?: any
  pageContext?: any
}

const StyledWithFragments = styled.div`
  & p,
  li {
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.color.grey};

    & b,
    strong {
      /* color: ${({ theme }) => theme.color.darkish}; */
      color: inherit;
    }
  }

  & a {
    color: ${({ theme }) => theme.color.primary};

    &:hover {
      text-decoration: underline;
    }
  }

  & img {
    object-fit: contain !important;
  }

  & li {
    list-style: none;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      left: -20px;
      background-color: ${({ theme }) => theme.color.primary};
      height: 8px;
      width: 8px;
      border-radius: 4px;
    }
  }

  & blockquote {
    padding: 80px 0 20px 60px;
    position: relative;

    & em {
      font-size: 24px !important;
    }

    & p {
      font-size: 15px !important;
      color: ${({ theme }) => theme.color.darkish};
      font-family: ${({ theme }) => theme.font.family.secondary};
      font-weight: ${({ theme }) => theme.font.weight.bold};
      padding-bottom: 20px;

      &:first-of-type {
        line-height: 34px;
      }

      & b,
      strong {
        color: ${({ theme }) => theme.color.primary};
        font-size: 16px;
        line-height: 140%;
      }
    }

    &::before {
      content: '“';
      position: absolute;
      font-family: ${({ theme }) => theme.font.family.secondary};
      font-weight: ${({ theme }) => theme.font.weight.bold};
      font-size: 165px;
      top: -50px;
      left: 0;
      color: ${({ theme }) => theme.color.primary};
    }
  }

  & h2 {
    font-size: 20px;
    line-height: 24px;
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    padding-top: 20px;
  }

  & h3 {
    font-size: 28px;
    line-height: 40px;
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    padding-bottom: 20px;
  }

  @media screen and (min-width: 992px) {
    padding-right: 100px;
  }

  @media screen and (max-width: 991px) {
    & blockquote {
      padding: 100px 0 20px 0px;
    }
  }
`

const Block = styled.div`
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  background-color: ${({ theme }) => theme.color.greyLight};
  padding: 2rem;

  & h1,
  & h2,
  & h3 {
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-weight: ${({ theme }) => theme.font.weight.extraBold};
    color: ${({ theme }) => theme.color.darkish};
    font-size: 26px;
    line-height: 31px;
  }

  & p {
    font-size: 16px;
    line-height: 22px;
    font-weight: ${({ theme }) => theme.font.weight.light};
    text-align: justify;
    margin-bottom: 20px;
  }

  @media screen and (min-width: 992px) {
    padding: 30px;
    max-width: 450px;
    margin: 0 auto;
  }
`

const ShareWrapper = styled.div`
  position: absolute;
  top: -40px;
`

const WithFragments: React.FC<WithFragmentsProps> = ({
  location,
  pageContext,
  fields,
}) => {
  const post = usePost()

  return (
    <section className="pt-4">
      <div className="container py-5">
        <div className={`row justify-content-${fields.alignment}`}>
          <StyledWithFragments
            className={`position-relative pb-lg-0 pb-4 col-lg-${fields.width}`}
          >
            {Object.keys(post).length !== 0 && (
              <ShareWrapper>
                <Share
                  body={`Bekijk dit klantverhaal van Veerman ICT! ${location.href}`}
                  subject="Veerman ICT"
                  location={location}
                />
              </ShareWrapper>
            )}
            <ParseContent content={fields.description} />
            {fields?.sortText && fields?.sortText === 'with_image' && (
              <div className="py-3 py-lg-5">
                {fields?.content?.map((item, index) => (
                  <div key={index} className="row py-3 py-lg-5 align-items-center">
                    <div className={`col-lg-5 ${item?.type === 'image_right' && 'order-lg-2'}`}>
                      <Plaatjie image={item?.image} alt="" />
                    </div>
                    <div className="col-lg-7">
                      <ParseContent content={item?.description} />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </StyledWithFragments>
          <div className={`col-lg-${12 - (fields.width || 11)}`}>
            <Block>
              <FlexContentHandler
                prefix="Fragment_FlexFragment"
                fields={fields.element?.flex_fragment}
                location={location}
                pageContext={pageContext}
              />
            </Block>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WithFragments
