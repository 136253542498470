import React from 'react'

// Images
import check from 'img/check.svg'

// Components
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'
import ButtonTransparent from 'components/elements/Buttons/ButtonTransparent'

interface PartnerBannerProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Components
}

const BannerWrapper = styled.div`
  position: relative;
  height: fit-content;
`

const ImageWrapper = styled.div`
  position: relative;
  max-height: 500px;
  z-index: -1;
  overflow: hidden;

  @media screen and (max-width: 991px) {
    height: 300px;
    margin-bottom: 250px;

    & img {
      height: 300px;
    }
  }
`

const ContentWrapper = styled.div`
  position: absolute;
  top: 65px;
  left: 0;
  height: 100%;
  width: 100%;
  color: ${(props) => props.theme.color.grey};

  @media screen and (max-width: 991px) {
    top: 200px;
  }
`

const Block = styled.div`
  background-color: ${({ theme }) => theme.color.light};
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  padding: 40px;
  border-radius: 25px;

  & h2 {
    color: ${({ theme }) => theme.color.dark};
    opacity: 0.15;
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    @media (min-width: 576px) {
      font-size: 46px;
    }
    @media (max-width: 575px) {
      font-size: 36px;
    }
  }

  & h3 {
    font-size: 26px;
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-weight: ${({ theme }) => theme.font.weight.medium};
  }

  & ul {
    padding-left: 60px;
    padding-bottom: 20px;
  }

  & li {
    color: ${({ theme }) => theme.color.dark};
    font-size: 18px;
    line-height: 1;
    padding: 10px 0;
    list-style: none;
    position: relative;

    &:before {
      content: '';
      background-image: url(${check});
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      width: 20px;
      height: 15px;
      left: -30px;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  @media screen and (max-width: 991px) {
    padding: 30px 30px 15px 30px;
  }
`

const Logo = styled(Plaatjie)`
  max-width: 250px;
`

const PartnerBanner: React.FC<PartnerBannerProps> = ({ fields }) => (
  <section className="py-5">
    <BannerWrapper>
      <ImageWrapper className="d-flex justify-content-center">
        <Plaatjie image={fields.image} alt="partner-banner" />
      </ImageWrapper>
      <ContentWrapper>
        <div className="container">
          <div className="row">
            <div className="col-lg-5 position-relative">
              <Block className="d-flex flex-column justify-content-between">
                <ParseContent content={fields.description} />
                <div className="d-flex align-items-center justify-content-between">
                  <ButtonTransparent to={fields.link?.url || '/'}>
                    {fields.link?.title}
                  </ButtonTransparent>
                  <Logo image={fields.logo} alt="partner-logo" />
                </div>
              </Block>
            </div>
          </div>
        </div>
      </ContentWrapper>
    </BannerWrapper>
  </section>
)

export default PartnerBanner
