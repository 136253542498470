import React from 'react'
import styled, { css } from 'styled-components'
import ButtonShell from 'components/elements/Buttons/ButtonShell'

// Images
import Chevron from 'img/green_chevron.inline.svg'

export const ButtonTransparentCSS = css`
  display: inline-block;

  & > a,
  & > button {
    background-color: transparent;
    color: ${(props) => props.theme.color.dark};
    width: 100%;
    height: 100%;
    display: block;
    text-align: center;
    transition: all 0.3s ease;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-family: ${({ theme }) => theme.font.family.secondary};

    &:hover {
      margin-right: 10px;
      color: ${({ theme }) => theme.color.grey} !important;
    }
  }

  & svg {
    width: 6px;
    height: 11px;
    margin-left: 0.5rem;
  }
`

const StyledButton = styled.span`
  ${ButtonTransparentCSS}
`

interface ButtonTransparentProps {
  isAnchor?: boolean
  isCustom?: boolean
  to: string
  children: any
  className?: string
}

const ButtonTransparent: React.FC<ButtonTransparentProps> = ({
  isAnchor = false,
  isCustom = false,
  to,
  children,
  className = '',
}) => (
  <StyledButton className={className}>
    {isCustom ? (
      children
    ) : (
      <ButtonShell to={to} isAnchor={isAnchor}>
        {children}
        <Chevron />
      </ButtonShell>
    )}
  </StyledButton>
)

export default ButtonTransparent
