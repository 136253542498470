import React from 'react'

// Images
import Whatsapp from 'img/grey_whatsapp.inline.svg'
import Facebook from 'img/grey_facebook.inline.svg'
import Linkedin from 'img/grey_linkedin.inline.svg'
import Mail from 'img/grey_mail.inline.svg'

// Third Party
import {
  WhatsappShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  EmailShareButton,
} from 'react-share'
import styled from 'styled-components'

interface ShareProps {
  location: any
  subject: string
  body: string
}

const Icon = styled.div`
  height: 18px;
  width: 18px;
  border-radius: 45px;
  transition: all 0.3s ease;

  & svg {
    height: 18px;
    width: 18px;
  }

  &:hover {
    & svg {
      fill: ${(props) => props.theme.color.secondary};
    }
  }
`

const Title = styled.div`
  font-size: 16px;
  color: ${({ theme }) => theme.color.grey};
`

const Share: React.FC<ShareProps> = ({ body, subject, location }) => {
  const shareUrl = location ? location.href : ''

  return (
    <section>
      <div className="d-flex align-items-center">
        <Title className="me-2">Delen</Title>
        <div className="d-flex align-items-center">
          <WhatsappShareButton url={shareUrl} className="me-2">
            <Icon className="d-flex justify-content-center align-items-center">
              <Whatsapp />
            </Icon>
          </WhatsappShareButton>
          <FacebookShareButton url={shareUrl} className="me-2">
            <Icon className="d-flex justify-content-center align-items-center">
              <Facebook />
            </Icon>
          </FacebookShareButton>
          <LinkedinShareButton url={shareUrl} className="me-2">
            <Icon className="d-flex justify-content-center align-items-center">
              <Linkedin />
            </Icon>
          </LinkedinShareButton>
          <EmailShareButton
            url={shareUrl}
            subject={subject}
            body={body}
            className="me-2"
          >
            <Icon className="d-flex justify-content-center align-items-center">
              <Mail />
            </Icon>
          </EmailShareButton>
        </div>
      </div>
    </section>
  )
}

export default Share
