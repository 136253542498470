import React from 'react'
import { useStaticQuery, graphql, navigate } from 'gatsby'

// Components
import Title from 'components/elements/Title'
import ParseContent from 'components/shared/ParseContent'

// Images
import Message from 'img/message.inline.svg'

// Third Party
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay } from 'swiper/core'
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'
import { cloneDeep } from 'lodash'

SwiperCore.use([Autoplay])

interface TeamPreviewProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Components
}

const ImageWrapper = styled.div`
  width: 100%;
  padding-top: 100%;
  position: relative;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
  border-radius: 25px;

  @media screen and (max-width: 991px) {
    width: 100%;
    margin-left: auto;
  }
`

const StyledPlaatjie = styled(Plaatjie)`
  position: absolute !important;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  border-radius: 25px;
`

const Content = styled(ParseContent)`
  /* font-family: ${({ theme }) => theme.font.family.secondary}; */

  & p {
    font-size: 18px;
    line-height: 24px;
    /* font-weight: ${({ theme }) => theme.font.weight.extraBold}; */

    & b,
    strong {
      font-size: 20px;
    }
  }

  @media screen and (max-width: 575px) {
    & p {
      font-size: 20px;
      line-height: 20px;
    }
  }

  @media screen and (max-width: 350px) {
    & p {
      font-size: 16px;
      line-height: 16px;
    }
  }

  @media screen and (max-width: 991px) {
    font-size: 14px;
    line-height: 18px;
    color: ${({ theme }) => theme.color.grey};
  }
`

const SwiperWrapper = styled.div`
  height: 100%;
  width: 120%;
  position: relative;

  & .swiper-slide {
    height: auto;
  }
`

const SwiperShadow = styled.div`
  position: absolute;
  left: 50%;
  top: -80px;
  height: 90%;
  width: 100%;
  background-color: ${({ theme }) => theme.color.primary};
  border-radius: 25px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);

  @media screen and (max-width: 991px) {
    left: 50%;
    top: -40px;
    height: 60%;
  }
`

const Bubble = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: ${({ theme }) => theme.color.primary};
  padding: 10px;
  border-radius: 999px;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }
`

const TeamPreview: React.FC<TeamPreviewProps> = ({ fields }) => {
  const [team, setTeam] = React.useState<GatsbyTypes.WpTeam[]>([])
  const query = useStaticQuery<GatsbyTypes.TeamPreviewQuery>(graphql`
    query TeamPreview {
      allWpTeam(sort: { fields: menuOrder, order: ASC }) {
        nodes {
          id
          title
          teamrecap {
            story {
              ... on WpPost {
                uri
              }
            }
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100, width: 500)
                }
              }
            }
            excerpt
          }
        }
      }
    }
  `)

  React.useEffect(() => {
    const t = cloneDeep(query.allWpTeam.nodes) as GatsbyTypes.WpTeam[]
    const firstTeam = t.pop()

    const correctTeam = [firstTeam, ...t] as GatsbyTypes.WpTeam[]

    setTeam(correctTeam)
  }, [])

  return (
    <section className="pb-5" id="team">
      <div className="container py-5">
        <Title>
          <ParseContent content={fields.backgroundtext} />
        </Title>
        <div>
          <SwiperWrapper>
            <SwiperShadow />
            <Swiper
              spaceBetween={10}
              slidesPerView={3}
              breakpoints={{
                991: {
                  spaceBetween: 10,
                  slidesPerView: 5,
                },
              }}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
            >
              {team.map((member) => (
                <SwiperSlide key={member.id}>
                  <div className="p-1">
                    <ImageWrapper>
                      <StyledPlaatjie
                        key={member.id}
                        image={member.teamrecap?.image}
                        alt={member.title}
                      />
                      {member.teamrecap?.story && (
                        <Bubble
                          onClick={() =>
                            navigate(member.teamrecap?.story?.uri || '/')
                          }
                        >
                          <Message />
                        </Bubble>
                      )}
                    </ImageWrapper>
                    <div className="py-lg-2 py-1 px-3">
                      <Content content={member.teamrecap?.excerpt} />
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </SwiperWrapper>
        </div>
        <div className="pt-4">
          <ButtonPrimary to={fields.link?.url || '/'}>
            {fields.link?.title}
          </ButtonPrimary>
        </div>
      </div>
    </section>
  )
}

export default TeamPreview
