import React from 'react'
import { navigate, Link } from 'gatsby'

// Images
import DarkChevron from 'img/dark_chevron.inline.svg'

// Components
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'
import { motion, AnimatePresence } from 'framer-motion'
import { useBlogBink } from '@ubo/blog-bink'

interface HighlightedStoriesGridPostProps {
  node: GatsbyTypes.WpPost
  paginate: Function
  variants: any
  direction: any
  page: any
}

const HighlightedStoriesGridPostExcerpt = styled(ParseContent)``

const Content = styled(motion.div)`
  background-color: ${({ theme }) => theme.color.light};
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  border-radius: 25px;
  padding: 50px 30px 30px 30px;
  width: 400px;
  position: absolute;
  right: 0;
  top: 100px;
  z-index: 1 !important;

  & p {
    color: ${({ theme }) => theme.color.dark};
    font-size: 19px;
    font-weight: ${({ theme }) => theme.font.weight.bold};

    b,
    strong {
      font-size: 16px;
      line-height: 22px;
    }
  }

  & h2 {
    font-size: 22px;
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    margin-bottom: 10px;
  }

  @media screen and (max-width: 991px) {
    width: 90%;
    left: 5%;
    top: 0px;
    z-index: 20 !important;
    position: relative;
  }
`

const ImageContainer = styled.div`
  height: 445px;
  width: 700px;
  position: relative;

  @media screen and (max-width: 991px) {
    width: 100%;
    height: auto;
  }
`

const ImageWrapper = styled(motion.div)`
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: 25px;
  margin-bottom: -40px;
  z-index: 1;
  cursor: pointer;
  overflow: hidden;

  & img {
    border-radius: 25px;
    height: 100%;
    max-height: 430px;
  }

  @media screen and (max-width: 991px) {
    width: 100%;
    height: auto;
    margin-bottom: -80px;
  }
`

const PostWrapper = styled.div`
  height: fit-content;
`

const ButtonWrapper = styled.div`
  position: absolute;
  top: -40px;
  right: 25px;
  z-index: 10;

  & > div {
    &:first-of-type {
      & svg {
        transform: rotate(180deg);
        margin-bottom: 2px;
      }
    }
    & svg {
      width: 10px;
      height: 17px;
    }
  }

  @media screen and (max-width: 991px) {
    top: -30px;

    & > div {
      & svg {
        path {
          fill: ${({ theme }) => theme.color.light};
        }
      }
    }
  }
`

const Quote = styled.div`
  position: absolute;
  color: ${({ theme }) => theme.color.primary};
  top: 0;
  left: 20px;
  font-size: 165px;
  line-height: 40px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-family: ${({ theme }) => theme.font.family.secondary};
`

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.color.grey};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  transition: all 0.3s ease;

  &:hover {
    color: ${({ theme }) => theme.color.primary};
    text-decoration: underline;
  }
`

const HighlightedStoriesGridPost: React.FC<HighlightedStoriesGridPostProps> = ({
  node,
  paginate,
  page,
  direction,
  variants,
}) => {
  const blogBink: any = useBlogBink()

  const changeIndex = (type: 'back' | 'next') => {
    if (type === 'next') {
      paginate(1)
    } else if (type === 'back') {
      paginate(-1)
    }
  }

  return (
    <PostWrapper className="position-relative">
      <ImageContainer>
        <AnimatePresence>
          <ImageWrapper
            key={page}
            custom={direction}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: { type: 'spring', stiffness: 300, damping: 30 },
              opacity: { duration: 0.2 },
            }}
            onClick={() => {
              navigate(node.uri || '/')
            }}
          >
            <Plaatjie image={node.recap?.image} alt="preview-post" />
          </ImageWrapper>
        </AnimatePresence>
      </ImageContainer>

      <AnimatePresence>
        <Content
          className="d-lg-block d-none"
          key={`${page}-text`}
          custom={direction}
          variants={variants}
          initial="textEnter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: 'spring', stiffness: 300, damping: 30 },
            opacity: { duration: 0.2 },
          }}
        >
          {blogBink.posts.length > 1 && (
            <ButtonWrapper className="d-flex align-items-center">
              <div
                className="me-3"
                role="button"
                tabIndex={-1}
                onKeyDown={() => changeIndex('back')}
                onClick={() => {
                  changeIndex('back')
                }}
              >
                <DarkChevron />
              </div>
              <div
                role="button"
                tabIndex={-1}
                onKeyDown={() => changeIndex('next')}
                onClick={() => {
                  changeIndex('next')
                }}
              >
                <DarkChevron />
              </div>
            </ButtonWrapper>
          )}

          <Quote>“</Quote>
          <HighlightedStoriesGridPostExcerpt content={node.recap?.quote} />
          <div className="pt-4 d-flex justify-content-end">
            <StyledLink to={node.uri || '/'}>Lees meer &gt;</StyledLink>
          </div>
        </Content>
        <Content className="d-lg-none d-block">
          {blogBink.posts.length > 1 && (
            <ButtonWrapper className="d-flex align-items-center">
              <div
                className="me-3"
                role="button"
                tabIndex={-1}
                onKeyDown={() => changeIndex('back')}
                onClick={() => {
                  changeIndex('back')
                }}
              >
                <DarkChevron />
              </div>
              <div
                role="button"
                tabIndex={-1}
                onKeyDown={() => changeIndex('next')}
                onClick={() => {
                  changeIndex('next')
                }}
              >
                <DarkChevron />
              </div>
            </ButtonWrapper>
          )}

          <Quote>“</Quote>
          <HighlightedStoriesGridPostExcerpt content={node.recap?.quote} />
        </Content>
      </AnimatePresence>
    </PostWrapper>
  )
}

export default HighlightedStoriesGridPost
